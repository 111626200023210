<template>
  <div class="weui-tab">
    <div class="weui-tab__bd public-container">
      <div
        class="
          public-areas public-area-user-list
          margin-bottom-10 margin-top-10
        "
      >
        <div class="weui-cells margin-top-0">
          <div class="weui-cell weui-cell_access gender" href="javascript:;">
            <div class="weui-cell__hd">
              <label class="weui-label">性别</label>
            </div>
            <div class="weui-cell__bd">
              <p>
                <input
                  class="weui-input attribute"
                  v-model="gender"
                  @click="genderChange"
                  type="text"
                  readonly="readonly"
                  value=""
                />
              </p>
            </div>
            <div class="weui-cell__ft"></div>
          </div>
          <div class="weui-cell weui-cell_access birthday" href="javascript:;">
            <div class="weui-cell__hd">
              <label class="weui-label">生日</label>
            </div>
            <div class="weui-cell__bd">
              <p>
                <input
                  class="weui-input attribute"
                  v-model="birthday"
                  @click="birthdayChange"
                  type="text"
                  readonly="readonly"
                  value=" "
                />
              </p>
            </div>
            <div class="weui-cell__ft"></div>
          </div>
          <div class="weui-cell weui-cell_access city" href="javascript:;">
            <div class="weui-cell__hd">
              <label class="weui-label">所在城市</label>
            </div>
            <div class="weui-cell__bd">
              <p>
                <input
                  class="weui-input attribute"
                  v-model="city"
                  @click="cityChange"
                  type="text"
                  readonly="readonly"
                  data-codes=""
                  value=""
                />
              </p>
            </div>
            <div class="weui-cell__ft"></div>
          </div>
          <div class="weui-cell weui-cell_access education" href="javascript:;">
            <div class="weui-cell__hd">
              <label class="weui-label">学历</label>
            </div>
            <div class="weui-cell__bd">
              <p>
                <input
                  class="weui-input attribute"
                  v-model="education"
                  @click="educationChange"
                  readonly="readonly"
                  type="text"
                  value=""
                />
              </p>
            </div>
            <div class="weui-cell__ft"></div>
          </div>
          <div class="weui-cell weui-cell_access job" href="javascript:;">
            <div class="weui-cell__hd">
              <label class="weui-label">工作情况</label>
            </div>
            <div class="weui-cell__bd">
              <p>
                <input
                  class="weui-input attribute"
                  v-model="job"
                  @click="jobChange"
                  readonly="readonly"
                  type="text"
                  value=""
                />
              </p>
            </div>
            <div class="weui-cell__ft"></div>
          </div>
          <div
            class="weui-cell weui-cell_access houseincome"
            href="javascript:;"
          >
            <div class="weui-cell__hd">
              <label class="weui-label">家庭月收入</label>
            </div>
            <div class="weui-cell__bd">
              <p>
                <input
                  class="weui-input attribute"
                  v-model="houseincome"
                  @click="houseincomeChange"
                  readonly="readonly"
                  type="text"
                />
              </p>
            </div>
            <div class="weui-cell__ft"></div>
          </div>

          <div class="weui-cell weui-cell_access car" href="javascript:;">
            <div class="weui-cell__hd">
              <label class="weui-label">私家车</label>
            </div>
            <div class="weui-cell__bd">
              <p>
                <input
                  class="weui-input attribute"
                  v-model="car"
                  @click="carChange"
                  readonly="readonly"
                  type="text"
                  data-codes=""
                />
              </p>
            </div>
            <div class="weui-cell__ft"></div>
          </div>
        </div>
      </div>
      <div class="public-area-submit-box">
        <div class="weui-cells margin-top-0">
          <div class="weui-cell">
            <div class="weui-cell__bd">
              <input type="hidden" id="page-type" value="attribute" />
              <a
                href="javascript:;"
                class="weui-btn weui-btn_primary public-btn-user-edit-attr"
                @click="doSetExtProperty"
                >保存</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <tabbar :selectedVal="'users'"></tabbar>
    <van-popup v-model="pickerVisible" position="bottom">
      <van-picker
        title="请选择性别"
        show-toolbar
        :columns="genderData"
        @confirm="genderConfirm"
        @cancel="onCancel"
        v-if="pickerType === 'gender'"
      />
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="请选择生日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="birthdayConfirm"
        @cancel="onCancel"
        v-if="pickerType === 'birthday'"
      />
      <van-area
        title="请选择城市"
        :area-list="areaList"
        @confirm="cityConfirm"
        @cancel="onCancel"
        v-if="pickerType === 'city'"
      />
      <van-picker
        title="请选择学历"
        show-toolbar
        :columns="educatioData"
        @confirm="educatioConfirm"
        @cancel="onCancel"
        v-if="pickerType === 'educatio'"
      />
      <van-picker
        title="请选择工作"
        show-toolbar
        :columns="jobData"
        @confirm="jobConfirm"
        @cancel="onCancel"
        v-if="pickerType === 'job'"
      />
      <van-picker
        title="请选择家庭月收入"
        show-toolbar
        :columns="houseincomeData"
        @confirm="houseincomeConfirm"
        @cancel="onCancel"
        v-if="pickerType === 'houseincome'"
      />
      <van-picker
        show-toolbar
        title="请选择私家车"
        :columns="carData"
        @confirm="carConfirm"
        @cancel="onCancel"
        v-if="pickerType === 'car'"
      />
    </van-popup>
  </div>
</template>

<script>
import { setExtProperty, getExtProperty } from '../api/index'

import moment from 'moment'
import tabbar from '../components/tabbar/index.vue'
import { areaList } from "@vant/area-data";
import Popup from 'vant/lib/popup'
import DatetimePicker from 'vant/lib/datetime-picker'
import Picker from 'vant/lib/picker'
import Area from 'vant/lib/area'
import "vant/lib/datetime-picker/style";
import "vant/lib/popup/style";
import "vant/lib/area/style";
import "vant/lib/picker/style";
import wxauth from '../mixin/wxauth'
export default {
  mixins: [wxauth],
  name: "attribute",
  components: {
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Picker.name]: Picker,
    [Area.name]: Area,
    tabbar
  },
  data() {
    return {
      datePickkerVisible: false,
      pickerVisible: false,
      pickerType: "",
      genderData: ["男", "女"],
      minDate: new Date(1970, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      areaList,
      educatioData: [
        "初中及以下",
        "高中/中专/技校",
        "大专",
        "本科",
        "硕士",
        "博士及以上",
      ],
      jobData: ["在职", "兼职", "学生", "自由工作者", "未工作"],
      houseincomeData: [
        "0 ~ 5,000元",
        "5,001元 ~ 10000元",
        "10,001元 ~ 15,000元",
        "15,001元 ~ 20,000元",
        "20,001元 ~ 25,000元",
        "25,001元 ~ 30,000元",
        "30,001元 ~ 35,000元",
        "35,001元 ~ 40,000元",
        "45,001元 ~ 50,000元",
        "50,001元 ~ 80,000元",
        "80,001元 ~",
      ],
      carData: [
        {
          text: "俺家没车",
          children: [
            {
              text: "---",
              children: [
                {
                  text: "---",
                },
              ],
            },
          ],
        },
        {
          text: "A - 奥迪",
          children: [
            {
              text: "一汽奥迪",
              children: [
                {
                  text: "A3",
                },
                {
                  text: "A4L",
                },
                {
                  text: "A6L",
                },
                {
                  text: "Q2L",
                },
                {
                  text: "Q3",
                },
                {
                  text: "Q5L",
                },
                {
                  text: "Q4",
                },
              ],
            },
            {
              text: "一汽奥迪新能源",
              children: [
                {
                  text: "A6L 插电混动",
                },
                {
                  text: "Q2L 纯电动",
                },
              ],
            },
            {
              text: "进口奥迪",
              children: [
                {
                  text: "A3",
                },
                {
                  text: "A4",
                },
                {
                  text: "A5",
                },
                {
                  text: "A6",
                },
                {
                  text: "A7",
                },
                {
                  text: "A8L",
                },
                {
                  text: "Q7",
                },
                {
                  text: "Q8",
                },
                {
                  text: "TT",
                },
                {
                  text: "A1",
                },
                {
                  text: "Q4",
                },
              ],
            },
            {
              text: "进口奥迪新能源",
              children: [
                {
                  text: "Q7 插电混动",
                },
                {
                  text: "Q4 e-tron",
                },
                {
                  text: "e-tron",
                },
                {
                  text: "e-tron GT",
                },
                {
                  text: "A7 插电混动",
                },
                {
                  text: "A8L 插电混动",
                },
              ],
            },
            {
              text: "进口Audi Sport",
              children: [
                {
                  text: "S3",
                },
                {
                  text: "S4",
                },
                {
                  text: "S5",
                },
                {
                  text: "S6",
                },
                {
                  text: "S7",
                },
                {
                  text: "TTS",
                },
                {
                  text: "RS 3",
                },
                {
                  text: "RS 4",
                },
                {
                  text: "RS 5",
                },
                {
                  text: "RS 6",
                },
                {
                  text: "RS 7",
                },
                {
                  text: "TT RS",
                },
                {
                  text: "R8",
                },
                {
                  text: "SQ2",
                },
                {
                  text: "SQ7",
                },
                {
                  text: "SQ8",
                },
                {
                  text: "RS Q3",
                },
                {
                  text: "RS Q8",
                },
              ],
            },
          ],
        },
        {
          text: "A - 阿尔法·罗密欧",
          children: [
            {
              text: "阿尔法·罗密欧",
              children: [
                {
                  text: "Stelvio",
                },
                {
                  text: "Giulia",
                },
                {
                  text: "Tonale",
                },
              ],
            },
          ],
        },
        {
          text: "A - 阿斯顿·马丁",
          children: [
            {
              text: "进口阿斯顿·马丁",
              children: [
                {
                  text: "Rapide",
                },
                {
                  text: "V8 Vantage",
                },
                {
                  text: "DB11",
                },
                {
                  text: "DBS",
                },
                {
                  text: "Vanquish",
                },
                {
                  text: "DBX",
                },
                {
                  text: "DBS GT Zagato",
                },
                {
                  text: "Valhalla",
                },
              ],
            },
            {
              text: "进口阿斯顿·马丁新能源",
              children: [
                {
                  text: "Rapide E",
                },
              ],
            },
          ],
        },
        {
          text: "A - 爱驰",
          children: [
            {
              text: "爱驰",
              children: [
                {
                  text: "U5",
                },
                {
                  text: "RG Nathalie",
                },
              ],
            },
          ],
        },
        {
          text: "A - ABT",
          children: [
            {
              text: "ABT",
              children: [
                {
                  text: "ABT Touareg",
                },
                {
                  text: "ABT Q7",
                },
                {
                  text: "ABT AS3",
                },
                {
                  text: "ABT AS4",
                },
                {
                  text: "ABT AS5",
                },
                {
                  text: "ABT AS6",
                },
                {
                  text: "ABT AS8",
                },
                {
                  text: "ABT TT",
                },
                {
                  text: "ABT R8",
                },
              ],
            },
          ],
        },
        {
          text: "A - AC Schnitzer",
          children: [
            {
              text: "AC Schnitzer",
              children: [
                {
                  text: "AC Schnitzer 1系",
                },
                {
                  text: "AC Schnitzer 3系",
                },
                {
                  text: "AC Schnitzer 5系",
                },
                {
                  text: "AC Schnitzer X1",
                },
                {
                  text: "AC Schnitzer X6",
                },
              ],
            },
          ],
        },
        {
          text: "A - 艾康尼克",
          children: [
            {
              text: "艾康尼克",
              children: [
                {
                  text: "艾康尼克7系",
                },
              ],
            },
          ],
        },
        {
          text: "A - ALPINA",
          children: [
            {
              text: "ALPINA",
              children: [
                {
                  text: "ALPINA B3",
                },
                {
                  text: "ALPINA B4",
                },
              ],
            },
          ],
        },
        {
          text: "A - ARCFOX",
          children: [
            {
              text: "ARCFOX",
              children: [
                {
                  text: "LITE",
                },
                {
                  text: "ECF",
                },
                {
                  text: "ARCFOX GT",
                },
              ],
            },
          ],
        },
        {
          text: "B - 宝骏",
          children: [
            {
              text: "上汽通用五菱",
              children: [
                {
                  text: "510",
                },
                {
                  text: "530",
                },
                {
                  text: "310",
                },
                {
                  text: "310W",
                },
                {
                  text: "360",
                },
                {
                  text: "730",
                },
              ],
            },
            {
              text: "上汽通用五菱新能源",
              children: [
                {
                  text: "E100",
                },
                {
                  text: "E200",
                },
              ],
            },
          ],
        },
        {
          text: "B - 宝马",
          children: [
            {
              text: "华晨宝马",
              children: [
                {
                  text: "1系",
                },
                {
                  text: "2系旅行车",
                },
                {
                  text: "3系",
                },
                {
                  text: "5系",
                },
                {
                  text: "X1",
                },
                {
                  text: "X2",
                },
                {
                  text: "X3",
                },
              ],
            },
            {
              text: "华晨宝马新能源",
              children: [
                {
                  text: "X1 插电混动",
                },
                {
                  text: "5系 插电混动",
                },
              ],
            },
            {
              text: "进口宝马",
              children: [
                {
                  text: "1系",
                },
                {
                  text: "2系",
                },
                {
                  text: "3系GT",
                },
                {
                  text: "4系",
                },
                {
                  text: "5系",
                },
                {
                  text: "6系",
                },
                {
                  text: "6系GT",
                },
                {
                  text: "7系",
                },
                {
                  text: "8系",
                },
                {
                  text: "X2",
                },
                {
                  text: "X4",
                },
                {
                  text: "X5",
                },
                {
                  text: "X6",
                },
                {
                  text: "X7",
                },
                {
                  text: "Z4",
                },
                {
                  text: "3系",
                },
                {
                  text: "iNEXT",
                },
              ],
            },
            {
              text: "进口宝马新能源",
              children: [
                {
                  text: "X5 插电混动",
                },
                {
                  text: "7系 插电混动",
                },
                {
                  text: "i3",
                },
                {
                  text: "i8",
                },
                {
                  text: "iX3",
                },
                {
                  text: "X3 插电混动",
                },
                {
                  text: "3系 插电混动",
                },
                {
                  text: "5系 插电混动",
                },
              ],
            },
            {
              text: "进口宝马M",
              children: [
                {
                  text: "M2",
                },
                {
                  text: "M3",
                },
                {
                  text: "M4",
                },
                {
                  text: "M5",
                },
                {
                  text: "M6",
                },
                {
                  text: "M8",
                },
                {
                  text: "X3 M",
                },
                {
                  text: "X4 M",
                },
                {
                  text: "X5 M",
                },
                {
                  text: "X6 M",
                },
              ],
            },
          ],
        },
        {
          text: "B - 奔驰",
          children: [
            {
              text: "北京奔驰",
              children: [
                {
                  text: "A级",
                },
                {
                  text: "C级",
                },
                {
                  text: "E级",
                },
                {
                  text: "GLA级",
                },
                {
                  text: "GLC级",
                },
                {
                  text: "GLB级",
                },
              ],
            },
            {
              text: "北京奔驰新能源",
              children: [
                {
                  text: "EQC",
                },
                {
                  text: "E级 插电混动",
                },
              ],
            },
            {
              text: "福建奔驰",
              children: [
                {
                  text: "V级",
                },
                {
                  text: "威霆",
                },
                {
                  text: "凌特",
                },
              ],
            },
            {
              text: "进口奔驰",
              children: [
                {
                  text: "A级",
                },
                {
                  text: "B级",
                },
                {
                  text: "C级",
                },
                {
                  text: "E级",
                },
                {
                  text: "S级",
                },
                {
                  text: "CLA级",
                },
                {
                  text: "CLS级",
                },
                {
                  text: "GLC级",
                },
                {
                  text: "GLE级",
                },
                {
                  text: "GLS级",
                },
                {
                  text: "G级",
                },
                {
                  text: "SLC级",
                },
                {
                  text: "SL级",
                },
                {
                  text: "GLB级",
                },
                {
                  text: "X级",
                },
              ],
            },
            {
              text: "进口梅赛德斯-迈巴赫",
              children: [
                {
                  text: "迈巴赫S级",
                },
              ],
            },
            {
              text: "进口梅赛德斯-AMG",
              children: [
                {
                  text: "AMG A级",
                },
                {
                  text: "AMG C级",
                },
                {
                  text: "AMG E级",
                },
                {
                  text: "AMG S级",
                },
                {
                  text: "AMG CLA级",
                },
                {
                  text: "AMG GLA级",
                },
                {
                  text: "AMG GLC级",
                },
                {
                  text: "AMG GLE级",
                },
                {
                  text: "AMG GLS级",
                },
                {
                  text: "AMG G级",
                },
                {
                  text: "AMG GT",
                },
                {
                  text: "AMG GLB级",
                },
              ],
            },
            {
              text: "北京奔驰-AMG",
              children: [
                {
                  text: "AMG A级",
                },
              ],
            },
            {
              text: "进口奔驰新能源",
              children: [
                {
                  text: "EQB",
                },
                {
                  text: "EQC",
                },
                {
                  text: "GLC级 插电混动",
                },
                {
                  text: "EQE",
                },
                {
                  text: "EQS",
                },
                {
                  text: "A级 插电混动",
                },
                {
                  text: "B级 插电混动",
                },
                {
                  text: "S级 插电混动",
                },
                {
                  text: "EQV",
                },
              ],
            },
          ],
        },
        {
          text: "B - 本田",
          children: [
            {
              text: "广汽本田",
              children: [
                {
                  text: "缤智",
                },
                {
                  text: "冠道",
                },
                {
                  text: "飞度",
                },
                {
                  text: "锋范",
                },
                {
                  text: "凌派",
                },
                {
                  text: "雅阁",
                },
                {
                  text: "奥德赛",
                },
                {
                  text: "皓影",
                },
              ],
            },
            {
              text: "东风本田",
              children: [
                {
                  text: "XR-V",
                },
                {
                  text: "CR-V",
                },
                {
                  text: "UR-V",
                },
                {
                  text: "竞瑞",
                },
                {
                  text: "哥瑞",
                },
                {
                  text: "杰德",
                },
                {
                  text: "享域",
                },
                {
                  text: "思域",
                },
                {
                  text: "INSPIRE",
                },
                {
                  text: "艾力绅",
                },
              ],
            },
            {
              text: "进口本田",
              children: [
                {
                  text: "BR-V",
                },
                {
                  text: "Pilot",
                },
                {
                  text: "Tj Cruiser",
                },
                {
                  text: "飞度",
                },
                {
                  text: "N-WGN",
                },
                {
                  text: "Shuttle",
                },
                {
                  text: "Freed",
                },
              ],
            },
            {
              text: "进口本田新能源",
              children: [
                {
                  text: "本田e",
                },
              ],
            },
          ],
        },
        {
          text: "B - 别克",
          children: [
            {
              text: "上汽通用别克",
              children: [
                {
                  text: "昂科拉",
                },
                {
                  text: "昂科拉GX",
                },
                {
                  text: "昂科威",
                },
                {
                  text: "凯越",
                },
                {
                  text: "阅朗",
                },
                {
                  text: "英朗",
                },
                {
                  text: "威朗",
                },
                {
                  text: "君威",
                },
                {
                  text: "君越",
                },
                {
                  text: "GL6",
                },
                {
                  text: "GL8",
                },
                {
                  text: "Enspire",
                },
                {
                  text: "昂科旗",
                },
              ],
            },
            {
              text: "上汽通用别克新能源",
              children: [
                {
                  text: "微蓝",
                },
              ],
            },
            {
              text: "进口别克",
              children: [
                {
                  text: "昂科雷",
                },
              ],
            },
          ],
        },
        {
          text: "B - 标致",
          children: [
            {
              text: "东风标致",
              children: [
                {
                  text: "2008",
                },
                {
                  text: "3008",
                },
                {
                  text: "4008",
                },
                {
                  text: "5008",
                },
                {
                  text: "301",
                },
                {
                  text: "308",
                },
                {
                  text: "408",
                },
                {
                  text: "508L",
                },
              ],
            },
            {
              text: "东风标致新能源",
              children: [
                {
                  text: "4008 PHEV",
                },
                {
                  text: "508L PHEV",
                },
              ],
            },
            {
              text: "进口标致",
              children: [
                {
                  text: "2008",
                },
                {
                  text: "208",
                },
              ],
            },
            {
              text: "进口标致新能源",
              children: [
                {
                  text: "e-2008",
                },
                {
                  text: "e-208",
                },
              ],
            },
          ],
        },
        {
          text: "B - 比亚迪",
          children: [
            {
              text: "比亚迪",
              children: [
                {
                  text: "元",
                },
                {
                  text: "宋",
                },
                {
                  text: "宋Pro",
                },
                {
                  text: "唐",
                },
                {
                  text: "F3",
                },
                {
                  text: "速锐",
                },
                {
                  text: "秦",
                },
                {
                  text: "秦Pro",
                },
                {
                  text: "宋MAX",
                },
               
              ],
            },
            {
              text: "比亚迪新能源",
              children: [
                {
                  text: "元EV",
                },
                {
                  text: "S2",
                },
                {
                  text: "宋DM",
                },
                {
                  text: "宋Pro DM",
                },
                {
                  text: "宋EV",
                },
                {
                  text: "宋Pro EV",
                },
                {
                  text: "唐DM",
                },
                {
                  text: "唐EV",
                },
                {
                  text: "e1",
                },
                {
                  text: "e2",
                },
                {
                  text: "e3",
                },
                {
                  text: "秦DM",
                },
                {
                  text: "秦Pro DM",
                },
                {
                  text: "秦EV",
                },
                {
                  text: "秦Pro EV",
                },
                {
                  text: "秦Plus",
                },
                {
                  text: "宋MAX DM",
                },
                {
                  text: "宋MAX EV",
                },
                 {
                  text: "汉EV",
                },
                {
                  text: "汉DM",
                },
                {
                  text: "商",
                },
                {
                  text: "e-SEED GT",
                },
                {
                  text: "T3",
                },
              ],
            },
          ],
        },
        {
          text: "B - 保时捷",
          children: [
            {
              text: "进口保时捷",
              children: [
                {
                  text: "Macan",
                },
                {
                  text: "Cayenne",
                },
                {
                  text: "Panamera",
                },
                {
                  text: "718",
                },
                {
                  text: "911",
                },
                {
                  text: "917",
                },
                {
                  text: "935",
                },
              ],
            },
            {
              text: "进口保时捷新能源",
              children: [
                {
                  text: "Cayenne 插电混动",
                },
                {
                  text: "Taycan",
                },
                {
                  text: "Panamera 插电混动",
                },
              ],
            },
          ],
        },
        {
          text: "B - 宝沃",
          children: [
            {
              text: "宝沃汽车",
              children: [
                {
                  text: "BX5",
                },
                {
                  text: "BX6",
                },
                {
                  text: "BX7",
                },
              ],
            },
            {
              text: "宝沃新能源",
              children: [
                {
                  text: "BXi7",
                },
              ],
            },
          ],
        },
        {
          text: "B - 奔腾",
          children: [
            {
              text: "一汽奔腾",
              children: [
                {
                  text: "X40",
                },
                {
                  text: "T33",
                },
                {
                  text: "X80",
                },
                {
                  text: "T77",
                },
                {
                  text: "T99",
                },
                {
                  text: "B30",
                },
                {
                  text: "B50",
                },
                {
                  text: "B70",
                },
              ],
            },
            {
              text: "一汽奔腾新能源",
              children: [
                {
                  text: "X40 EV",
                },
                {
                  text: "B30 EV",
                },
                {
                  text: "C105",
                },
              ],
            },
          ],
        },
        {
          text: "B - 比速",
          children: [
            {
              text: "比速",
              children: [
                {
                  text: "T3",
                },
                {
                  text: "T5",
                },
                {
                  text: "M3",
                },
                {
                  text: "T7",
                },
              ],
            },
          ],
        },
        {
          text: "B - 北京汽车",
          children: [
            {
              text: "北京汽车",
              children: [
                {
                  text: "X25",
                },
                {
                  text: "X35",
                },
                {
                  text: "BEIJING·X3",
                },
                {
                  text: "智行",
                },
                {
                  text: "X65",
                },
                {
                  text: "D50",
                },
                {
                  text: "D70",
                },
                {
                  text: "BEIJING·U7",
                },
                {
                  text: "ILLUMINATE",
                },
              ],
            },
          ],
        },
        {
          text: "B - 北汽幻速",
          children: [
            {
              text: "北汽幻速",
              children: [
                {
                  text: "S2",
                },
                {
                  text: "S3",
                },
                {
                  text: "S5",
                },
                {
                  text: "S6",
                },
                {
                  text: "S7",
                },
                {
                  text: "H2",
                },
                {
                  text: "H3",
                },
                {
                  text: "H5",
                },
                {
                  text: "H6",
                },
              ],
            },
          ],
        },
        {
          text: "B - 北汽威旺",
          children: [
            {
              text: "北汽威旺",
              children: [
                {
                  text: "S50",
                },
                {
                  text: "M20",
                },
                {
                  text: "M30",
                },
                {
                  text: "M50F",
                },
                {
                  text: "M60",
                },
                {
                  text: "306",
                },
                {
                  text: "307",
                },
              ],
            },
            {
              text: "北汽威旺新能源",
              children: [
                {
                  text: "北汽威旺407EV",
                },
              ],
            },
          ],
        },
        {
          text: "B - 北汽昌河",
          children: [
            {
              text: "北汽昌河",
              children: [
                {
                  text: "Q25",
                },
                {
                  text: "Q35",
                },
                {
                  text: "Q7",
                },
                {
                  text: "北斗星",
                },
                {
                  text: "A6",
                },
                {
                  text: "M50",
                },
                {
                  text: "M60",
                },
                {
                  text: "M70",
                },
                {
                  text: "福瑞达K21",
                },
                {
                  text: "福瑞达K22",
                },
              ],
            },
            {
              text: "北汽昌河新能源",
              children: [
                {
                  text: "北斗星EC100",
                },
                {
                  text: "北斗星-快闪",
                },
                {
                  text: "北斗星X5E",
                },
                {
                  text: "EV5",
                },
              ],
            },
          ],
        },
        {
          text: "B - 北汽制造",
          children: [
            {
              text: "北汽制造",
              children: [
                {
                  text: "BJ 212",
                },
                {
                  text: "勇士",
                },
                {
                  text: "勇士皮卡",
                },
                {
                  text: "越铃",
                },
                {
                  text: "战旗",
                },
                {
                  text: "角斗士",
                },
                {
                  text: "锐铃",
                },
                {
                  text: "战旗皮卡",
                },
              ],
            },
          ],
        },
        {
          text: "B - 宝腾",
          children: [
            {
              text: "宝腾",
              children: [
                {
                  text: "X70",
                },
              ],
            },
          ],
        },
        {
          text: "B - 北京清行",
          children: [
            {
              text: "北京清行",
              children: [
                {
                  text: "清行400",
                },
              ],
            },
          ],
        },
        {
          text: "B - 北汽道达",
          children: [
            {
              text: "北汽道达",
              children: [
                {
                  text: "道达V8",
                },
              ],
            },
          ],
        },
        {
          text: "B - 比德文汽车",
          children: [
            {
              text: "比德文汽车",
              children: [
                {
                  text: "比德文E3",
                },
              ],
            },
          ],
        },
        {
          text: "B - 铂驰",
          children: [
            {
              text: "铂驰",
              children: [
                {
                  text: "铂驰奔驰Sprinter系列",
                },
                {
                  text: "铂驰奔驰V系列",
                },
                {
                  text: "铂驰大众T6系列",
                },
              ],
            },
          ],
        },
        {
          text: "B - 博郡汽车",
          children: [
            {
              text: "博郡汽车",
              children: [
                {
                  text: "iV6",
                },
                {
                  text: "iV7",
                },
              ],
            },
          ],
        },
        {
          text: "B - 北汽新能源",
          children: [
            {
              text: "北汽新能源",
              children: [
                {
                  text: "EX系列",
                },
                {
                  text: "EX3",
                },
                {
                  text: "EX5",
                },
                {
                  text: "EC系列",
                },
                {
                  text: "EC3",
                },
                {
                  text: "EC5",
                },
                {
                  text: "EU系列",
                },
                {
                  text: "EU5",
                },
                {
                  text: "BEIJING·EU7",
                },
              ],
            },
          ],
        },
        {
          text: "B - 北京",
          children: [
            {
              text: "北京",
              children: [
                {
                  text: "BJ20",
                },
                {
                  text: "BJ40",
                },
                {
                  text: "BJ80",
                },
                {
                  text: "F40",
                },
                {
                  text: "BJ90",
                },
              ],
            },
          ],
        },
        {
          text: "B - 拜腾",
          children: [
            {
              text: "拜腾",
              children: [
                {
                  text: "K-Byte",
                },
                {
                  text: "M-Byte",
                },
              ],
            },
          ],
        },
        {
          text: "B - 宾利",
          children: [
            {
              text: "进口宾利",
              children: [
                {
                  text: "添越",
                },
                {
                  text: "欧陆",
                },
                {
                  text: "飞驰",
                },
                {
                  text: "慕尚",
                },
              ],
            },
            {
              text: "进口宾利新能源",
              children: [
                {
                  text: "添越PHEV",
                },
              ],
            },
          ],
        },
        {
          text: "B - 布加迪",
          children: [
            {
              text: "布加迪",
              children: [
                {
                  text: "Chiron",
                },
                {
                  text: "Divo",
                },
                {
                  text: "Galibier",
                },
                {
                  text: "威航",
                },
                {
                  text: "Centodieci",
                },
              ],
            },
          ],
        },
        {
          text: "C - 长安汽车",
          children: [
            {
              text: "长安汽车",
              children: [
                {
                  text: "CS15",
                },
                {
                  text: "CS35",
                },
                {
                  text: "CS35 PLUS",
                },
                {
                  text: "CS55",
                },
                {
                  text: "CS75",
                },
                {
                  text: "CS75 PLUS",
                },
                {
                  text: "CS85 COUPE",
                },
                {
                  text: "CS95",
                },
                {
                  text: "奔奔",
                },
                {
                  text: "悦翔",
                },
                {
                  text: "逸动DT",
                },
                {
                  text: "逸动XT",
                },
                {
                  text: "逸动",
                },
                {
                  text: "睿骋CC",
                },
                {
                  text: "锐程CC",
                },
                {
                  text: "睿骋",
                },
                {
                  text: "凌轩",
                },
                {
                  text: "CS55 PLUS",
                },
              ],
            },
            {
              text: "长安新能源",
              children: [
                {
                  text: "CS15 EV",
                },
                {
                  text: "CS75 PHEV",
                },
                {
                  text: "奔奔EV",
                },
                {
                  text: "逸动ET",
                },
                {
                  text: "逸动PHEV",
                },
                {
                  text: "逸动EV",
                },
                {
                  text: "E-ROCK",
                },
              ],
            },
          ],
        },
        {
          text: "C - 长安欧尚",
          children: [
            {
              text: "长安汽车",
              children: [
                {
                  text: "长安欧尚科赛3",
                },
                {
                  text: "长安欧尚科赛5",
                },
                {
                  text: "长安欧尚X70A",
                },
                {
                  text: "长安欧尚CX70",
                },
                {
                  text: "长安欧尚科赛",
                },
                {
                  text: "欧诺S",
                },
                {
                  text: "欧尚长行",
                },
                {
                  text: "长安欧尚A600",
                },
                {
                  text: "长安欧尚A800",
                },
                {
                  text: "长安欧尚科尚",
                },
                {
                  text: "长安之星9",
                },
                {
                  text: "长安星卡",
                },
                {
                  text: "长安欧尚X7",
                },
              ],
            },
            {
              text: "长安新能源",
              children: [
                {
                  text: "长安之星9 EV",
                },
                {
                  text: "E01",
                },
                {
                  text: "尼欧II",
                },
                {
                  text: "欧力威EV",
                },
                {
                  text: "欧诺S EV",
                },
                {
                  text: "欧尚长行EV",
                },
                {
                  text: "欧尚EV",
                },
                {
                  text: "欧尚A600 EV",
                },
                {
                  text: "长安星卡EV",
                },
              ],
            },
          ],
        },
        {
          text: "C - 长安轻型车",
          children: [
            {
              text: "长安轻型车",
              children: [
                {
                  text: "睿行S50",
                },
                {
                  text: "睿行S50T",
                },
                {
                  text: "睿行M60",
                },
                {
                  text: "睿行M70",
                },
                {
                  text: "睿行M80",
                },
                {
                  text: "睿行M90",
                },
                {
                  text: "神骐F30",
                },
                {
                  text: "神骐T10",
                },
                {
                  text: "神骐T20",
                },
                {
                  text: "凯程F70",
                },
                {
                  text: "长安星卡L系列",
                },
                {
                  text: "长安星卡C系列",
                },
              ],
            },
            {
              text: "长安轻型车新能源",
              children: [
                {
                  text: "睿行EM80",
                },
                {
                  text: "睿行ES30",
                },
                {
                  text: "睿行EM60",
                },
              ],
            },
          ],
        },
        {
          text: "C - 长安跨越",
          children: [
            {
              text: "长安跨越",
              children: [
                {
                  text: "V3",
                },
                {
                  text: "跨越王X1",
                },
                {
                  text: "跨越王X3",
                },
                {
                  text: "跨越王X5",
                },
                {
                  text: "新豹2",
                },
                {
                  text: "新豹3",
                },
                {
                  text: "新豹T3",
                },
                {
                  text: "新豹MINI",
                },
                {
                  text: "跨越者D5",
                },
              ],
            },
            {
              text: "长安跨越新能源",
              children: [
                {
                  text: "V3 EV",
                },
                {
                  text: "V5 EV",
                },
              ],
            },
          ],
        },
        {
          text: "C - Cupra",
          children: [
            {
              text: "Cupra",
              children: [
                {
                  text: "Tavascan",
                },
              ],
            },
          ],
        },
        {
          text: "C - 长城",
          children: [
            {
              text: "长城汽车",
              children: [
                {
                  text: "风骏5",
                },
                {
                  text: "风骏6",
                },
                {
                  text: "风骏7",
                },
                {
                  text: "炮",
                },
              ],
            },
            {
              text: "长城新能源",
              children: [
                {
                  text: "C30 EV",
                },
                {
                  text: "风骏7 EV",
                },
                {
                  text: "炮EV",
                },
              ],
            },
          ],
        },
        {
          text: "C - 成功",
          children: [
            {
              text: "成功",
              children: [
                {
                  text: "成功BEV6",
                },
                {
                  text: "成功V2E",
                },
              ],
            },
          ],
        },
        {
          text: "D - 大众",
          children: [
            {
              text: "上汽大众",
              children: [
                {
                  text: "T-Cross途铠",
                },
                {
                  text: "途岳",
                },
                {
                  text: "途观",
                },
                {
                  text: "途观L",
                },
                {
                  text: "途昂",
                },
                {
                  text: "途昂X",
                },
                {
                  text: "POLO",
                },
                {
                  text: "桑塔纳",
                },
                {
                  text: "朗逸",
                },
                {
                  text: "凌渡",
                },
                {
                  text: "帕萨特",
                },
                {
                  text: "辉昂",
                },
                {
                  text: "途安L",
                },
                {
                  text: "Viloran",
                },
              ],
            },
            {
              text: "上汽大众新能源",
              children: [
                {
                  text: "途观L 插电混动",
                },
                {
                  text: "朗逸纯电",
                },
                {
                  text: "帕萨特 插电混动",
                },
              ],
            },
            {
              text: "一汽-大众",
              children: [
                {
                  text: "探歌",
                },
                {
                  text: "探岳",
                },
                {
                  text: "宝来",
                },
                {
                  text: "高尔夫",
                },
                {
                  text: "高尔夫·嘉旅",
                },
                {
                  text: "蔚领",
                },
                {
                  text: "速腾",
                },
                {
                  text: "迈腾",
                },
                {
                  text: "CC",
                },
                {
                  text: "探影",
                },
              ],
            },
            {
              text: "一汽-大众新能源",
              children: [
                {
                  text: "高尔夫·纯电",
                },
                {
                  text: "宝来·纯电",
                },
                {
                  text: "探岳 插电混动",
                },
                {
                  text: "迈腾 插电混动",
                },
              ],
            },
            {
              text: "进口大众",
              children: [
                {
                  text: "Tiguan",
                },
                {
                  text: "途锐",
                },
                {
                  text: "高尔夫",
                },
                {
                  text: "甲壳虫",
                },
                {
                  text: "蔚揽",
                },
                {
                  text: "夏朗",
                },
                {
                  text: "迈特威",
                },
                {
                  text: "凯路威",
                },
                {
                  text: "T-ROC",
                },
                {
                  text: "T-Cross",
                },
                {
                  text: "Atlas",
                },
                {
                  text: "POLO",
                },
              ],
            },
            {
              text: "进口大众新能源",
              children: [
                {
                  text: "高尔夫 纯电动",
                },
                {
                  text: "蔚揽 插电混动",
                },
                {
                  text: "ID.4",
                },
                {
                  text: "途锐 插电混动",
                },
                {
                  text: "UP! 纯电动",
                },
                {
                  text: "ID.3",
                },
                {
                  text: "甲壳虫 纯电动",
                },
                {
                  text: "ID. Space Vizzio",
                },
              ],
            },
          ],
        },
        {
          text: "D - 大乘汽车",
          children: [
            {
              text: "大乘汽车",
              children: [
                {
                  text: "G60",
                },
                {
                  text: "G60S",
                },
                {
                  text: "G70S",
                },
              ],
            },
            {
              text: "大乘汽车新能源",
              children: [
                {
                  text: "G60E",
                },
                {
                  text: "E20",
                },
              ],
            },
          ],
        },
        {
          text: "D - 大发",
          children: [
            {
              text: "进口大发",
              children: [
                {
                  text: "Waku Waku",
                },
                {
                  text: "Copen",
                },
              ],
            },
            {
              text: "进口大发新能源",
              children: [
                {
                  text: "ICO ICO",
                },
                {
                  text: "Wai Wai",
                },
                {
                  text: "Tsumu Tsumu",
                },
              ],
            },
          ],
        },
        {
          text: "D - 东风风度",
          children: [
            {
              text: "东风风度",
              children: [
                {
                  text: "MX5",
                },
                {
                  text: "MX6",
                },
                {
                  text: "MX3",
                },
              ],
            },
          ],
        },
        {
          text: "D - 东风风光",
          children: [
            {
              text: "东风风光",
              children: [
                {
                  text: "S560",
                },
                {
                  text: "580",
                },
                {
                  text: "580Pro",
                },
                {
                  text: "ix5",
                },
                {
                  text: "330",
                },
                {
                  text: "370",
                },
                {
                  text: "ix7",
                },
              ],
            },
            {
              text: "东风风光新能源",
              children: [
                {
                  text: "580 PHEV",
                },
                {
                  text: "E1",
                },
                {
                  text: "E3 电动版",
                },
                {
                  text: "E3 增程版",
                },
              ],
            },
          ],
        },
        {
          text: "D - 东风风神",
          children: [
            {
              text: "东风风神",
              children: [
                {
                  text: "AX3",
                },
                {
                  text: "AX4",
                },
                {
                  text: "AX5",
                },
                {
                  text: "AX7",
                },
                {
                  text: "奕炫",
                },
                {
                  text: "A60",
                },
                {
                  text: "L60",
                },
                {
                  text: "A9",
                },
              ],
            },
            {
              text: "东风风神新能源",
              children: [
                {
                  text: "E70",
                },
                {
                  text: "EX1",
                },
                {
                  text: "AX7 PHEV",
                },
                {
                  text: "AX7 FCV",
                },
                {
                  text: "奕炫EV",
                },
              ],
            },
          ],
        },
        {
          text: "D - 东风风行",
          children: [
            {
              text: "东风风行",
              children: [
                {
                  text: "景逸X3",
                },
                {
                  text: "景逸X5",
                },
                {
                  text: "景逸X6",
                },
                {
                  text: "景逸S50",
                },
                {
                  text: "风行T5",
                },
                {
                  text: "风行T5L",
                },
                {
                  text: "风行SX6",
                },
                {
                  text: "风行S500",
                },
                {
                  text: "风行F600",
                },
                {
                  text: "风行M6",
                },
                {
                  text: "风行M7",
                },
                {
                  text: "风行CM7",
                },
                {
                  text: "菱智",
                },
              ],
            },
            {
              text: "东风风行新能源",
              children: [
                {
                  text: "风行S50 EV",
                },
                {
                  text: "菱智M5 EV",
                },
                {
                  text: "风行T1 EV",
                },
              ],
            },
          ],
        },
        {
          text: "D - 东风富康",
          children: [
            {
              text: "东风富康",
              children: [
                {
                  text: "富康ES500",
                },
              ],
            },
          ],
        },
        {
          text: "D - 东风小康",
          children: [
            {
              text: "东风小康",
              children: [
                {
                  text: "C31",
                },
                {
                  text: "C32",
                },
                {
                  text: "C35",
                },
                {
                  text: "C36",
                },
                {
                  text: "C37",
                },
                {
                  text: "D51",
                },
                {
                  text: "D52",
                },
                {
                  text: "K01",
                },
                {
                  text: "K02",
                },
                {
                  text: "K05S",
                },
                {
                  text: "K07S",
                },
                {
                  text: "D55",
                },
              ],
            },
            {
              text: "东风小康新能源",
              children: [
                {
                  text: "EC36",
                },
              ],
            },
          ],
        },
        {
          text: "D - 东风",
          children: [
            {
              text: "郑州日产",
              children: [
                {
                  text: "帅客",
                },
                {
                  text: "锐骐皮卡",
                },
                {
                  text: "锐骐6",
                },
              ],
            },
            {
              text: "郑州日产新能源",
              children: [
                {
                  text: "帅客EV",
                },
                {
                  text: "锐骐EV",
                },
                {
                  text: "锐骐6 EV",
                },
              ],
            },
            {
              text: "东风御风",
              children: [
                {
                  text: "S16",
                },
                {
                  text: "P16",
                },
                {
                  text: "A100",
                },
              ],
            },
            {
              text: "东风汽车",
              children: [
                {
                  text: "东风皮卡",
                },
              ],
            },
            {
              text: "东风新能源",
              children: [
                {
                  text: "俊风ER30",
                },
                {
                  text: "俊风E11K",
                },
                {
                  text: "俊风E17",
                },
                {
                  text: "EM10",
                },
                {
                  text: "EM13",
                },
                {
                  text: "EV350",
                },
              ],
            },
            {
              text: "东风御风新能源",
              children: [
                {
                  text: "EA100",
                },
                {
                  text: "物流王",
                },
              ],
            },
          ],
        },
        {
          text: "D - 东风·瑞泰特",
          children: [
            {
              text: "东风·瑞泰特",
              children: [
                {
                  text: "东风·瑞泰特EM10",
                },
                {
                  text: "东风·瑞泰特EM13",
                },
                {
                  text: "东风·瑞泰特EM30",
                },
              ],
            },
          ],
        },
        {
          text: "D - 东南",
          children: [
            {
              text: "东南汽车",
              children: [
                {
                  text: "DX3",
                },
                {
                  text: "DX5",
                },
                {
                  text: "DX7",
                },
                {
                  text: "V5菱致",
                },
                {
                  text: "A5翼舞",
                },
                {
                  text: "DX9",
                },
              ],
            },
            {
              text: "东南汽车新能源",
              children: [
                {
                  text: "DX3 EV",
                },
              ],
            },
          ],
        },
        {
          text: "D - 道奇",
          children: [
            {
              text: "道奇",
              children: [
                {
                  text: "酷威",
                },
                {
                  text: "Charger",
                },
              ],
            },
          ],
        },
        {
          text: "D - DS",
          children: [
            {
              text: "长安标致雪铁龙",
              children: [
                {
                  text: "DS 4S",
                },
                {
                  text: "DS 5",
                },
                {
                  text: "DS 5LS",
                },
                {
                  text: "DS 6",
                },
                {
                  text: "DS 7",
                },
                {
                  text: "DS 9",
                },
              ],
            },
            {
              text: "长安标致雪铁龙新能源",
              children: [
                {
                  text: "DS 9 PHEV",
                },
              ],
            },
            {
              text: "进口DS新能源",
              children: [
                {
                  text: "DS 3 纯电动",
                },
              ],
            },
          ],
        },
        {
          text: "D - 电咖",
          children: [
            {
              text: "电咖",
              children: [
                {
                  text: "EV10",
                },
              ],
            },
          ],
        },
        {
          text: "F - 丰田",
          children: [
            {
              text: "一汽丰田",
              children: [
                {
                  text: "奕泽",
                },
                {
                  text: "RAV4荣放",
                },
                {
                  text: "普拉多",
                },
                {
                  text: "威驰FS",
                },
                {
                  text: "威驰",
                },
                {
                  text: "卡罗拉",
                },
                {
                  text: "亚洲龙",
                },
                {
                  text: "皇冠",
                },
                {
                  text: "柯斯达",
                },
              ],
            },
            {
              text: "一汽丰田新能源",
              children: [
                {
                  text: "卡罗拉双擎E+",
                },
                {
                  text: "奕泽EV",
                },
              ],
            },
            {
              text: "广汽丰田",
              children: [
                {
                  text: "C-HR",
                },
                {
                  text: "汉兰达",
                },
                {
                  text: "YARiS L 致炫",
                },
                {
                  text: "YARiS L 致享",
                },
                {
                  text: "雷凌",
                },
                {
                  text: "凯美瑞",
                },
                {
                  text: "威兰达",
                },
              ],
            },
            {
              text: "广汽丰田新能源",
              children: [
                {
                  text: "雷凌双擎E+",
                },
                {
                  text: "C-HR EV",
                },
              ],
            },
            {
              text: "进口丰田",
              children: [
                {
                  text: "威尔法",
                },
                {
                  text: "埃尔法",
                },
                {
                  text: "86",
                },
                {
                  text: "RAIZE",
                },
                {
                  text: "C-HR",
                },
                {
                  text: "4Runner",
                },
                {
                  text: "YARiS",
                },
                {
                  text: "亚洲龙",
                },
                {
                  text: "Granvia",
                },
                {
                  text: "Sienna",
                },
                {
                  text: "Supra",
                },
                {
                  text: "GR Super Sport",
                },
                {
                  text: "塔科马",
                },
              ],
            },
            {
              text: "进口丰田新能源",
              children: [
                {
                  text: "RAV4 PHEV",
                },
                {
                  text: "e-4me",
                },
                {
                  text: "Ultra-compact BE",
                },
                {
                  text: "LQ",
                },
                {
                  text: "Mirai",
                },
                {
                  text: "e-Care",
                },
                {
                  text: "e-Trans",
                },
                {
                  text: "e-RACER",
                },
                {
                  text: "e-Palette",
                },
                {
                  text: "APM",
                },
              ],
            },
          ],
        },
        {
          text: "F - 福特",
          children: [
            {
              text: "长安福特",
              children: [
                {
                  text: "翼搏",
                },
                {
                  text: "翼虎",
                },
                {
                  text: "锐界",
                },
                {
                  text: "福睿斯",
                },
                {
                  text: "福克斯",
                },
                {
                  text: "福克斯Active",
                },
                {
                  text: "蒙迪欧",
                },
                {
                  text: "金牛座",
                },
                {
                  text: "Escape",
                },
              ],
            },
            {
              text: "长安福特新能源",
              children: [
                {
                  text: "蒙迪欧 插电混动",
                },
              ],
            },
            {
              text: "江铃福特",
              children: [
                {
                  text: "领界",
                },
                {
                  text: "撼路者",
                },
                {
                  text: "途睿欧",
                },
                {
                  text: "全顺",
                },
                {
                  text: "新世代全顺",
                },
              ],
            },
            {
              text: "江铃福特新能源",
              children: [
                {
                  text: "领界EV",
                },
              ],
            },
            {
              text: "进口福特",
              children: [
                {
                  text: "探险者",
                },
                {
                  text: "福克斯",
                },
                {
                  text: "MUSTANG",
                },
                {
                  text: "F-150",
                },
                {
                  text: "Ranger",
                },
                {
                  text: "PUMA",
                },
                {
                  text: "S-MAX",
                },
                {
                  text: "Galaxy",
                },
                {
                  text: "Bronco",
                },
                {
                  text: "Tourneo Custom",
                },
                {
                  text: "福特GT",
                },
              ],
            },
            {
              text: "进口福特新能源",
              children: [
                {
                  text: "C-MAX",
                },
                {
                  text: "探险者 插电混动",
                },
                {
                  text: "Tourneo Custom 插",
                },
                {
                  text: "Mustang 纯电动",
                },
              ],
            },
          ],
        },
        {
          text: "F - 菲亚特",
          children: [
            {
              text: "菲亚特",
              children: [
                {
                  text: "致悦",
                },
                {
                  text: "菲翔",
                },
              ],
            },
          ],
        },
        {
          text: "F - 法拉利",
          children: [
            {
              text: "法拉利",
              children: [
                {
                  text: "488",
                },
                {
                  text: "812",
                },
                {
                  text: "F8",
                },
                {
                  text: "GTC4Lusso",
                },
                {
                  text: "Portofino",
                },
                {
                  text: "J50",
                },
                {
                  text: "Monza SP1",
                },
                {
                  text: "Monza SP2",
                },
                {
                  text: "Sergio",
                },
                {
                  text: "SF90 Stradale",
                },
                {
                  text: "SP38",
                },
                {
                  text: "SP3JC",
                },
              ],
            },
          ],
        },
        {
          text: "F - 福迪",
          children: [
            {
              text: "福迪",
              children: [
                {
                  text: "揽福",
                },
                {
                  text: "雄师F16",
                },
                {
                  text: "雄师F22",
                },
              ],
            },
          ],
        },
        {
          text: "F - 福汽启腾",
          children: [
            {
              text: "福汽启腾",
              children: [
                {
                  text: "V60",
                },
                {
                  text: "M70",
                },
              ],
            },
            {
              text: "福汽启腾新能源",
              children: [
                {
                  text: "M70 EV",
                },
              ],
            },
          ],
        },
        {
          text: "F - 福田",
          children: [
            {
              text: "福田汽车",
              children: [
                {
                  text: "伽途GT",
                },
                {
                  text: "萨瓦纳",
                },
                {
                  text: "伽途im",
                },
                {
                  text: "伽途ix",
                },
                {
                  text: "萨普",
                },
                {
                  text: "拓陆者E3",
                },
                {
                  text: "拓陆者E5",
                },
                {
                  text: "拓陆者E7",
                },
                {
                  text: "拓陆者S",
                },
              ],
            },
            {
              text: "福田商用车",
              children: [
                {
                  text: "风景V3",
                },
                {
                  text: "风景V5",
                },
                {
                  text: "风景",
                },
                {
                  text: "风景G7",
                },
                {
                  text: "风景G9",
                },
                {
                  text: "风景G5",
                },
                {
                  text: "图雅诺",
                },
                {
                  text: "欧马可",
                },
                {
                  text: "祥菱V",
                },
                {
                  text: "祥菱M",
                },
                {
                  text: "奥铃捷运",
                },
                {
                  text: "祥菱S",
                },
              ],
            },
            {
              text: "福田商用车新能源",
              children: [
                {
                  text: "图雅诺EV",
                },
                {
                  text: "递哥",
                },
                {
                  text: "风景V3 EV",
                },
                {
                  text: "风景V5 EV",
                },
                {
                  text: "拓陆者EV",
                },
                {
                  text: "奥铃EV",
                },
              ],
            },
          ],
        },
        {
          text: "F - Faraday Future",
          children: [
            {
              text: "Faraday Future",
              children: [
                {
                  text: "FF 91",
                },
              ],
            },
          ],
        },
        {
          text: "G - 广汽传祺",
          children: [
            {
              text: "广汽传祺",
              children: [
                {
                  text: "GS3",
                },
                {
                  text: "GS4",
                },
                {
                  text: "GS5",
                },
                {
                  text: "GS7",
                },
                {
                  text: "GS8",
                },
                {
                  text: "GA4",
                },
                {
                  text: "GA6",
                },
                {
                  text: "GA8",
                },
                {
                  text: "GM6",
                },
                {
                  text: "GM8",
                },
              ],
            },
          ],
        },
        {
          text: "G - 广汽新能源",
          children: [
            {
              text: "广汽新能源",
              children: [
                {
                  text: "GE3",
                },
                {
                  text: "Aion LX",
                },
                {
                  text: "Aion S",
                },
              ],
            },
          ],
        },
        {
          text: "G - 广汽集团",
          children: [
            {
              text: "广汽本田新能源",
              children: [
                {
                  text: "世锐PHEV",
                },
              ],
            },
            {
              text: "广汽丰田新能源",
              children: [
                {
                  text: "ix4",
                },
                {
                  text: "iA5",
                },
              ],
            },
            {
              text: "广汽三菱新能源",
              children: [
                {
                  text: "祺智EV",
                },
                {
                  text: "祺智PHEV",
                },
              ],
            },
          ],
        },
        {
          text: "G - 高合",
          children: [
            {
              text: "高合",
              children: [
                {
                  text: "HiPhi 1",
                },
                {
                  text: "Concept A",
                },
                {
                  text: "Concept U",
                },
                {
                  text: "Concept H",
                },
              ],
            },
          ],
        },
        {
          text: "G - 格罗夫",
          children: [
            {
              text: "格罗夫",
              children: [
                {
                  text: "欧思典",
                },
              ],
            },
          ],
        },
        {
          text: "G - GFG Style",
          children: [
            {
              text: "GFG Style",
              children: [
                {
                  text: "Kangaroo",
                },
              ],
            },
          ],
        },
        {
          text: "G - 观致",
          children: [
            {
              text: "观致汽车",
              children: [
                {
                  text: "观致3",
                },
                {
                  text: "观致5",
                },
                {
                  text: "Model Young",
                },
              ],
            },
            {
              text: "观致新能源",
              children: [
                {
                  text: "观致3 EV",
                },
              ],
            },
          ],
        },
        {
          text: "G - 国机智骏",
          children: [
            {
              text: "国机智骏",
              children: [
                {
                  text: "GX5",
                },
                {
                  text: "GC1",
                },
                {
                  text: "GC2",
                },
                {
                  text: "GT3",
                },
              ],
            },
          ],
        },
        {
          text: "G - 国金",
          children: [
            {
              text: "国金",
              children: [
                {
                  text: "GM3",
                },
              ],
            },
          ],
        },
        {
          text: "G - 国能汽车",
          children: [
            {
              text: "国能汽车",
              children: [
                {
                  text: "NEVS 93",
                },
              ],
            },
          ],
        },
        {
          text: "G - GMC",
          children: [
            {
              text: "GMC",
              children: [
                {
                  text: "SAVANA",
                },
                {
                  text: "SIERRA",
                },
                {
                  text: "YUKON",
                },
              ],
            },
          ],
        },
        {
          text: "H - 哈弗",
          children: [
            {
              text: "哈弗",
              children: [
                {
                  text: "H2",
                },
                {
                  text: "H2s",
                },
                {
                  text: "H4",
                },
                {
                  text: "F5",
                },
                {
                  text: "H5",
                },
                {
                  text: "M6",
                },
                {
                  text: "H6",
                },
                {
                  text: "H6 Coupe",
                },
                {
                  text: "H7",
                },
                {
                  text: "F7",
                },
                {
                  text: "F7x",
                },
                {
                  text: "H9",
                },
              ],
            },
          ],
        },
        {
          text: "H - 海马",
          children: [
            {
              text: "一汽海马",
              children: [
                {
                  text: "S7",
                },
                {
                  text: "福美来F5",
                },
                {
                  text: "福美来F7",
                },
                {
                  text: "福美来F8",
                },
              ],
            },
            {
              text: "海马汽车",
              children: [
                {
                  text: "S5青春版",
                },
                {
                  text: "S5",
                },
                {
                  text: "8S",
                },
                {
                  text: "M3",
                },
                {
                  text: "M6",
                },
              ],
            },
            {
              text: "海马新能源",
              children: [
                {
                  text: "爱尚EV",
                },
                {
                  text: "海马E3",
                },
              ],
            },
          ],
        },
        {
          text: "H - 汉腾",
          children: [
            {
              text: "汉腾",
              children: [
                {
                  text: "X5",
                },
                {
                  text: "X7",
                },
                {
                  text: "V7",
                },
                {
                  text: "X8",
                },
              ],
            },
            {
              text: "汉腾新能源",
              children: [
                {
                  text: "X5 EV",
                },
                {
                  text: "X7 PHEV",
                },
                {
                  text: "幸福e+",
                },
                {
                  text: "汉腾FCV",
                },
              ],
            },
          ],
        },
        {
          text: "H - 红旗",
          children: [
            {
              text: "一汽红旗",
              children: [
                {
                  text: "HS5",
                },
                {
                  text: "HS7",
                },
                {
                  text: "H5",
                },
                {
                  text: "H7",
                },
                {
                  text: "L5",
                },
                {
                  text: "S9",
                },
              ],
            },
            {
              text: "一汽红旗新能源",
              children: [
                {
                  text: "E-HS3",
                },
                {
                  text: "E115",
                },
              ],
            },
          ],
        },
        {
          text: "H - 华泰",
          children: [
            {
              text: "华泰汽车",
              children: [
                {
                  text: "圣达菲5",
                },
                {
                  text: "圣达菲7",
                },
              ],
            },
            {
              text: "华泰新能源",
              children: [
                {
                  text: "圣达菲5 XEV260",
                },
                {
                  text: "路盛S1 EV160R",
                },
                {
                  text: "路盛S1 iEV360",
                },
                {
                  text: "圣达菲2 XEV360",
                },
                {
                  text: "路盛S1 EV160B",
                },
              ],
            },
          ],
        },
        {
          text: "H - 海格",
          children: [
            {
              text: "海格",
              children: [
                {
                  text: "H4E",
                },
                {
                  text: "H7V",
                },
              ],
            },
          ],
        },
        {
          text: "H - 汉龙汽车",
          children: [
            {
              text: "汉龙汽车",
              children: [
                {
                  text: "L12",
                },
                {
                  text: "L10",
                },
              ],
            },
          ],
        },
        {
          text: "H - 恒天",
          children: [
            {
              text: "恒天",
              children: [
                {
                  text: "恒好EV1",
                },
                {
                  text: "恒好EV7",
                },
              ],
            },
          ],
        },
        {
          text: "H - 红星汽车",
          children: [
            {
              text: "红星汽车",
              children: [
                {
                  text: "闪闪X2",
                },
              ],
            },
          ],
        },
        {
          text: "H - 黄海",
          children: [
            {
              text: "黄海",
              children: [
                {
                  text: "N1",
                },
                {
                  text: "N1S",
                },
                {
                  text: "N2",
                },
                {
                  text: "N3",
                },
                {
                  text: "N7",
                },
              ],
            },
          ],
        },
        {
          text: "H - 华骐",
          children: [
            {
              text: "华骐",
              children: [
                {
                  text: "300E",
                },
              ],
            },
          ],
        },
        {
          text: "H - 华颂",
          children: [
            {
              text: "华颂",
              children: [
                {
                  text: "华颂7",
                },
              ],
            },
          ],
        },
        {
          text: "H - HYCAN合创",
          children: [
            {
              text: "HYCAN合创",
              children: [
                {
                  text: "HYCAN概念车",
                },
              ],
            },
          ],
        },
        {
          text: "J - 吉利",
          children: [
            {
              text: "吉利汽车",
              children: [
                {
                  text: "远景X1",
                },
                {
                  text: "远景X3",
                },
                {
                  text: "缤越",
                },
                {
                  text: "远景S1",
                },
                {
                  text: "远景X6",
                },
                {
                  text: "帝豪GS",
                },
                {
                  text: "博越",
                },
                {
                  text: "星越",
                },
                {
                  text: "金刚",
                },
                {
                  text: "远景",
                },
                {
                  text: "帝豪",
                },
                {
                  text: "帝豪GL",
                },
                {
                  text: "缤瑞",
                },
                {
                  text: "博瑞",
                },
                {
                  text: "嘉际",
                },
                {
                  text: "ICON",
                },
              ],
            },
            {
              text: "吉利新能源",
              children: [
                {
                  text: "缤越PHEV",
                },
                {
                  text: "帝豪GSe",
                },
                {
                  text: "星越PHEV",
                },
                {
                  text: "帝豪EV",
                },
                {
                  text: "帝豪PHEV",
                },
                {
                  text: "帝豪GL PHEV",
                },
                {
                  text: "博瑞PHEV",
                },
                {
                  text: "嘉际PHEV",
                },
              ],
            },
          ],
        },
        {
          text: "J - 江淮",
          children: [
            {
              text: "江淮乘用车",
              children: [
                {
                  text: "瑞风S2",
                },
                {
                  text: "瑞风S3",
                },
                {
                  text: "瑞风S4",
                },
                {
                  text: "瑞风S5",
                },
                {
                  text: "瑞风S7",
                },
                {
                  text: "瑞风A60",
                },
                {
                  text: "瑞风R3",
                },
                {
                  text: "瑞风M3",
                },
                {
                  text: "瑞风M4",
                },
                {
                  text: "瑞风M5",
                },
                {
                  text: "瑞风M6",
                },
                {
                  text: "星锐",
                },
                {
                  text: "帅铃T6",
                },
                {
                  text: "帅铃T8",
                },
                {
                  text: "V7",
                },
                {
                  text: "嘉悦A5",
                },
                {
                  text: "凌铃",
                },
              ],
            },
            {
              text: "江淮新能源",
              children: [
                {
                  text: "iEV7S",
                },
                {
                  text: "iEVS4",
                },
                {
                  text: "iEV6E",
                },
                {
                  text: "iEV7",
                },
                {
                  text: "iEVA50",
                },
                {
                  text: "iEVA60",
                },
                {
                  text: "帅铃i3",
                },
              ],
            },
          ],
        },
        {
          text: "J - 捷豹",
          children: [
            {
              text: "奇瑞捷豹路虎",
              children: [
                {
                  text: "E-PACE",
                },
                {
                  text: "XEL",
                },
                {
                  text: "XFL",
                },
              ],
            },
            {
              text: "进口捷豹",
              children: [
                {
                  text: "F-PACE",
                },
                {
                  text: "XE",
                },
                {
                  text: "XF",
                },
                {
                  text: "XJ",
                },
                {
                  text: "F-TYPE",
                },
              ],
            },
            {
              text: "进口捷豹新能源",
              children: [
                {
                  text: "I-PACE",
                },
                {
                  text: "Vision Gran Turi",
                },
              ],
            },
          ],
        },
        {
          text: "J - Jeep",
          children: [
            {
              text: "广汽菲克Jeep",
              children: [
                {
                  text: "自由侠",
                },
                {
                  text: "指南者",
                },
                {
                  text: "自由光",
                },
                {
                  text: "指挥官",
                },
                {
                  text: "大指挥官",
                },
              ],
            },
            {
              text: "广汽菲克Jeep新能源",
              children: [
                {
                  text: "指挥官PHEV",
                },
              ],
            },
            {
              text: "进口Jeep",
              children: [
                {
                  text: "牧马人",
                },
                {
                  text: "大切诺基",
                },
                {
                  text: "Gladiator",
                },
              ],
            },
          ],
        },
        {
          text: "J - 捷途",
          children: [
            {
              text: "奇瑞汽车",
              children: [
                {
                  text: "X70",
                },
                {
                  text: "X70S",
                },
                {
                  text: "X90",
                },
                {
                  text: "X70M",
                },
                {
                  text: "X95",
                },
              ],
            },
            {
              text: "奇瑞新能源",
              children: [
                {
                  text: "X70S EV",
                },
                {
                  text: "X70 EV",
                },
              ],
            },
          ],
        },
        {
          text: "J - 江铃",
          children: [
            {
              text: "江铃汽车",
              children: [
                {
                  text: "宝典",
                },
                {
                  text: "凯锐800",
                },
                {
                  text: "凯运强劲版",
                },
                {
                  text: "凯运升级版",
                },
                {
                  text: "顺达宽体",
                },
                {
                  text: "顺达窄体",
                },
                {
                  text: "特顺",
                },
                {
                  text: "域虎3",
                },
                {
                  text: "域虎5",
                },
                {
                  text: "域虎7",
                },
                {
                  text: "域虎9",
                },
              ],
            },
            {
              text: "江铃新能源",
              children: [
                {
                  text: "E100B",
                },
                {
                  text: "E160",
                },
                {
                  text: "E200L",
                },
                {
                  text: "E200N",
                },
                {
                  text: "易至EV3",
                },
                {
                  text: "易至EX5",
                },
                {
                  text: "特顺EV",
                },
                {
                  text: "域虎EV",
                },
              ],
            },
            {
              text: "江铃轻汽",
              children: [
                {
                  text: "骐铃T5",
                },
                {
                  text: "骐铃T7",
                },
                {
                  text: "骐铃T100",
                },
                {
                  text: "骐铃T15",
                },
              ],
            },
            {
              text: "江铃旅居车",
              children: [
                {
                  text: "D-MAX房车",
                },
                {
                  text: "考斯特房车",
                },
                {
                  text: "旅居房车",
                },
                {
                  text: "罗莎房车租赁款",
                },
                {
                  text: "全顺T型房车",
                },
                {
                  text: "商旅房车",
                },
                {
                  text: "商旅房车经典款",
                },
                {
                  text: "途睿欧商务车",
                },
                {
                  text: "骐铃T7皮卡房车",
                },
                {
                  text: "全顺商旅房车",
                },
              ],
            },
          ],
        },
        {
          text: "J - 捷达",
          children: [
            {
              text: "捷达",
              children: [
                {
                  text: "VS5",
                },
                {
                  text: "VA3",
                },
                {
                  text: "VS7",
                },
              ],
            },
          ],
        },
        {
          text: "J - 几何汽车",
          children: [
            {
              text: "几何汽车",
              children: [
                {
                  text: "几何A",
                },
              ],
            },
          ],
        },
        {
          text: "J - 奇点汽车",
          children: [
            {
              text: "奇点汽车",
              children: [
                {
                  text: "iS6",
                },
                {
                  text: "iC3",
                },
              ],
            },
          ],
        },
        {
          text: "J - 捷尼赛思",
          children: [
            {
              text: "进口现代",
              children: [
                {
                  text: "G90",
                },
              ],
            },
            {
              text: "进口现代新能源",
              children: [
                {
                  text: "GV80",
                },
                {
                  text: "Mint",
                },
              ],
            },
          ],
        },
        {
          text: "J - 金杯",
          children: [
            {
              text: "华晨雷诺",
              children: [
                {
                  text: "观境",
                },
                {
                  text: "金杯F50",
                },
                {
                  text: "金杯海狮",
                },
                {
                  text: "金杯新快运",
                },
                {
                  text: "大海狮",
                },
                {
                  text: "阁瑞斯",
                },
              ],
            },
            {
              text: "华晨鑫源",
              children: [
                {
                  text: "750",
                },
                {
                  text: "小海狮X30",
                },
                {
                  text: "海狮X30L",
                },
                {
                  text: "新海狮S",
                },
                {
                  text: "T20",
                },
                {
                  text: "T22",
                },
                {
                  text: "T30",
                },
                {
                  text: "T32",
                },
                {
                  text: "T50",
                },
                {
                  text: "T52",
                },
              ],
            },
            {
              text: "华晨华瑞",
              children: [
                {
                  text: "智尚S35",
                },
                {
                  text: "金杯S70",
                },
                {
                  text: "大力神K5",
                },
                {
                  text: "金典",
                },
                {
                  text: "西部牛仔",
                },
              ],
            },
            {
              text: "华晨雷诺新能源",
              children: [
                {
                  text: "领坤EV",
                },
              ],
            },
          ],
        },
        {
          text: "J - 金龙",
          children: [
            {
              text: "金龙",
              children: [
                {
                  text: "金威",
                },
                {
                  text: "凯歌",
                },
                {
                  text: "凯锐浩克",
                },
                {
                  text: "凯特",
                },
              ],
            },
          ],
        },
        {
          text: "J - 金旅",
          children: [
            {
              text: "金旅客车",
              children: [
                {
                  text: "金旅新时代海狮",
                },
                {
                  text: "金旅窄体大海狮",
                },
                {
                  text: "金旅海狮",
                },
                {
                  text: "金旅大海狮",
                },
                {
                  text: "金旅领特",
                },
              ],
            },
            {
              text: "金旅客车新能源",
              children: [
                {
                  text: "金旅海狮EV",
                },
                {
                  text: "金旅大海狮EV",
                },
              ],
            },
          ],
        },
        {
          text: "J - 九龙",
          children: [
            {
              text: "九龙",
              children: [
                {
                  text: "艾菲",
                },
                {
                  text: "A4",
                },
                {
                  text: "A5",
                },
                {
                  text: "A6",
                },
                {
                  text: "C6",
                },
              ],
            },
            {
              text: "九龙新能源",
              children: [
                {
                  text: "EM3",
                },
                {
                  text: "EM5",
                },
                {
                  text: "EF5",
                },
                {
                  text: "A6E",
                },
              ],
            },
          ],
        },
        {
          text: "J - 极星",
          children: [
            {
              text: "极星",
              children: [
                {
                  text: "Polestar 2",
                },
                {
                  text: "Polestar 1",
                },
              ],
            },
          ],
        },
        {
          text: "J - 君马",
          children: [
            {
              text: "君马",
              children: [
                {
                  text: "美图3",
                },
                {
                  text: "赛克5",
                },
                {
                  text: "S70",
                },
                {
                  text: "MEET 5",
                },
              ],
            },
          ],
        },
        {
          text: "J - 钧天汽车",
          children: [
            {
              text: "钧天汽车",
              children: [
                {
                  text: "勇猛者",
                },
              ],
            },
          ],
        },
        {
          text: "K - 凯迪拉克",
          children: [
            {
              text: "上汽通用凯迪拉克",
              children: [
                {
                  text: "XT4",
                },
                {
                  text: "XT5",
                },
                {
                  text: "XT6",
                },
                {
                  text: "ATS-L",
                },
                {
                  text: "XTS",
                },
                {
                  text: "CT6",
                },
                {
                  text: "CT4",
                },
                {
                  text: "CT5",
                },
              ],
            },
            {
              text: "进口凯迪拉克",
              children: [
                {
                  text: "凯雷德",
                },
                {
                  text: "CT4",
                },
                {
                  text: "CT5",
                },
              ],
            },
          ],
        },
        {
          text: "K - 凯翼",
          children: [
            {
              text: "凯翼",
              children: [
                {
                  text: "X3",
                },
                {
                  text: "X5",
                },
                {
                  text: "V3",
                },
                {
                  text: "E3",
                },
              ],
            },
          ],
        },
        {
          text: "K - 克莱斯勒",
          children: [
            {
              text: "进口克莱斯勒",
              children: [
                {
                  text: "300C",
                },
                {
                  text: "大捷龙",
                },
              ],
            },
            {
              text: "进口克莱斯勒新能源",
              children: [
                {
                  text: "大捷龙 插电混动",
                },
              ],
            },
          ],
        },
        {
          text: "K - 开瑞",
          children: [
            {
              text: "开瑞",
              children: [
                {
                  text: "K60",
                },
                {
                  text: "K50",
                },
                {
                  text: "优劲",
                },
                {
                  text: "优优",
                },
              ],
            },
            {
              text: "开瑞新能源",
              children: [
                {
                  text: "K50EV",
                },
                {
                  text: "K60EV",
                },
                {
                  text: "优劲EV",
                },
                {
                  text: "优优EV",
                },
                {
                  text: "海豚EV",
                },
              ],
            },
          ],
        },
        {
          text: "K - 开沃汽车",
          children: [
            {
              text: "开沃汽车",
              children: [
                {
                  text: "D07",
                },
                {
                  text: "D08",
                },
                {
                  text: "D09",
                },
                {
                  text: "D10",
                },
                {
                  text: "D11",
                },
              ],
            },
          ],
        },
        {
          text: "K - Karma",
          children: [
            {
              text: "Karma",
              children: [
                {
                  text: "Revero",
                },
                {
                  text: "SC1 Vision",
                },
                {
                  text: "SC2",
                },
                {
                  text: "Pininfarina",
                },
              ],
            },
          ],
        },
        {
          text: "K - 卡升",
          children: [
            {
              text: "卡升",
              children: [
                {
                  text: "V级",
                },
                {
                  text: "威霆",
                },
                {
                  text: "卡升C10",
                },
              ],
            },
          ],
        },
        {
          text: "K - 卡威",
          children: [
            {
              text: "卡威汽车",
              children: [
                {
                  text: "路易斯",
                },
                {
                  text: "卡威K1",
                },
                {
                  text: "卡威K150GT",
                },
              ],
            },
            {
              text: "卡威新能源",
              children: [
                {
                  text: "卡威W1新能源",
                },
              ],
            },
          ],
        },
        {
          text: "K - 卡尔森",
          children: [
            {
              text: "卡尔森",
              children: [
                {
                  text: "CCA22",
                },
              ],
            },
          ],
        },
        {
          text: "L - 雷克萨斯",
          children: [
            {
              text: "进口雷克萨斯",
              children: [
                {
                  text: "UX",
                },
                {
                  text: "NX",
                },
                {
                  text: "RX",
                },
                {
                  text: "LX",
                },
                {
                  text: "CT",
                },
                {
                  text: "IS",
                },
                {
                  text: "ES",
                },
                {
                  text: "GS",
                },
                {
                  text: "LS",
                },
                {
                  text: "RC",
                },
                {
                  text: "LC",
                },
                {
                  text: "LM",
                },
              ],
            },
            {
              text: "进口雷克萨斯新能源",
              children: [
                {
                  text: "LF-30",
                },
              ],
            },
          ],
        },
        {
          text: "L - 雷诺",
          children: [
            {
              text: "东风雷诺",
              children: [
                {
                  text: "科雷缤",
                },
                {
                  text: "科雷嘉",
                },
                {
                  text: "科雷傲",
                },
              ],
            },
            {
              text: "东风雷诺新能源",
              children: [
                {
                  text: "雷诺e诺",
                },
              ],
            },
            {
              text: "进口雷诺",
              children: [
                {
                  text: "卡缤",
                },
                {
                  text: "Duster",
                },
                {
                  text: "ESPACE",
                },
                {
                  text: "Triber",
                },
                {
                  text: "Arkana",
                },
                {
                  text: "Twingo",
                },
                {
                  text: "ZOE",
                },
                {
                  text: "Clio",
                },
                {
                  text: "梅甘娜",
                },
                {
                  text: "Alaskan",
                },
                {
                  text: "Alpine A110",
                },
              ],
            },
          ],
        },
        {
          text: "L - 领克",
          children: [
            {
              text: "领克汽车",
              children: [
                {
                  text: "01",
                },
                {
                  text: "02",
                },
                {
                  text: "03",
                },
                {
                  text: "05",
                },
              ],
            },
            {
              text: "领克汽车新能源",
              children: [
                {
                  text: "01 PHEV",
                },
                {
                  text: "02 PHEV",
                },
                {
                  text: "03 PHEV",
                },
                {
                  text: "05 PHEV",
                },
              ],
            },
          ],
        },
        {
          text: "L - 林肯",
          children: [
            {
              text: "进口林肯汽车",
              children: [
                {
                  text: "MKC",
                },
                {
                  text: "航海家",
                },
                {
                  text: "领航员",
                },
                {
                  text: "MKZ",
                },
                {
                  text: "大陆",
                },
                {
                  text: "飞行家",
                },
              ],
            },
            {
              text: "长安林肯",
              children: [
                {
                  text: "Corsair",
                },
              ],
            },
            {
              text: "进口林肯汽车新能源",
              children: [
                {
                  text: "飞行家PHEV",
                },
              ],
            },
          ],
        },
        {
          text: "L - 陆风",
          children: [
            {
              text: "陆风",
              children: [
                {
                  text: "X2",
                },
                {
                  text: "X5",
                },
                {
                  text: "X7",
                },
                {
                  text: "逍遥",
                },
                {
                  text: "X8",
                },
                {
                  text: "荣曜",
                },
              ],
            },
          ],
        },
        {
          text: "L - 路虎",
          children: [
            {
              text: "奇瑞捷豹路虎",
              children: [
                {
                  text: "发现神行",
                },
                {
                  text: "揽胜极光",
                },
              ],
            },
            {
              text: "进口路虎",
              children: [
                {
                  text: "揽胜极光",
                },
                {
                  text: "揽胜星脉",
                },
                {
                  text: "发现",
                },
                {
                  text: "揽胜运动",
                },
                {
                  text: "揽胜",
                },
                {
                  text: "发现神行",
                },
                {
                  text: "卫士",
                },
              ],
            },
            {
              text: "进口路虎新能源",
              children: [
                {
                  text: "揽胜运动 插电混动",
                },
                {
                  text: "揽胜 插电混动",
                },
              ],
            },
          ],
        },
        {
          text: "L - 力帆",
          children: [
            {
              text: "力帆",
              children: [
                {
                  text: "迈威",
                },
                {
                  text: "X80",
                },
                {
                  text: "820",
                },
                {
                  text: "轩朗",
                },
                {
                  text: "乐途",
                },
                {
                  text: "650",
                },
              ],
            },
            {
              text: "力帆新能源",
              children: [
                {
                  text: "650EV",
                },
                {
                  text: "820EV",
                },
              ],
            },
          ],
        },
        {
          text: "L - 铃木",
          children: [
            {
              text: "长安铃木",
              children: [
                {
                  text: "维特拉",
                },
                {
                  text: "骁途",
                },
                {
                  text: "奥拓",
                },
                {
                  text: "雨燕",
                },
                {
                  text: "启悦",
                },
                {
                  text: "天语 SX4",
                },
              ],
            },
            {
              text: "昌河铃木",
              children: [
                {
                  text: "北斗星",
                },
                {
                  text: "北斗星X5",
                },
              ],
            },
            {
              text: "进口铃木",
              children: [
                {
                  text: "吉姆尼",
                },
                {
                  text: "英格尼斯",
                },
                {
                  text: "超级维特拉",
                },
                {
                  text: "HUSTLER",
                },
                {
                  text: "奥拓",
                },
                {
                  text: "HANARE",
                },
              ],
            },
            {
              text: "进口铃木新能源",
              children: [
                {
                  text: "Wako SPO",
                },
              ],
            },
          ],
        },
        {
          text: "L - 劳斯莱斯",
          children: [
            {
              text: "劳斯莱斯",
              children: [
                {
                  text: "库里南",
                },
                {
                  text: "古思特",
                },
                {
                  text: "幻影",
                },
                {
                  text: "魅影",
                },
                {
                  text: "曜影",
                },
              ],
            },
          ],
        },
        {
          text: "L - 兰博基尼",
          children: [
            {
              text: "兰博基尼",
              children: [
                {
                  text: "Urus",
                },
                {
                  text: "Huracan",
                },
                {
                  text: "Aventador",
                },
                {
                  text: "Sian",
                },
              ],
            },
          ],
        },
        {
          text: "L - LEVC",
          children: [
            {
              text: "LEVC",
              children: [
                {
                  text: "TX",
                },
              ],
            },
          ],
        },
        {
          text: "L - 猎豹",
          children: [
            {
              text: "猎豹汽车",
              children: [
                {
                  text: "CS9",
                },
                {
                  text: "CS10",
                },
                {
                  text: "Q6",
                },
                {
                  text: "Mattu",
                },
                {
                  text: "CT7",
                },
                {
                  text: "猎豹Coupe",
                },
              ],
            },
            {
              text: "猎豹汽车新能源",
              children: [
                {
                  text: "CS9 EV",
                },
                {
                  text: "缤歌",
                },
              ],
            },
          ],
        },
        {
          text: "L - 零跑汽车",
          children: [
            {
              text: "零跑汽车",
              children: [
                {
                  text: "C-more",
                },
                {
                  text: "S01",
                },
              ],
            },
          ],
        },
        {
          text: "L - 领途汽车",
          children: [
            {
              text: "领途汽车",
              children: [
                {
                  text: "K-ONE",
                },
                {
                  text: "S3",
                },
                {
                  text: "E行",
                },
              ],
            },
          ],
        },
        {
          text: "L - 理念",
          children: [
            {
              text: "理念",
              children: [
                {
                  text: "理念VE-1",
                },
              ],
            },
          ],
        },
        {
          text: "L - 理想",
          children: [
            {
              text: "理想",
              children: [
                {
                  text: "ONE",
                },
              ],
            },
          ],
        },
        {
          text: "L - 罗伦士",
          children: [
            {
              text: "罗伦士",
              children: [
                {
                  text: "Lorinser GS",
                },
                {
                  text: "Lorinser MAYBACH",
                },
                {
                  text: "Lorinser VS",
                },
                {
                  text: "Sprinter LS500",
                },
              ],
            },
          ],
        },
        {
          text: "L - 路特斯",
          children: [
            {
              text: "进口路特斯",
              children: [
                {
                  text: "Evora",
                },
              ],
            },
            {
              text: "进口路特斯新能源",
              children: [
                {
                  text: "Evija",
                },
              ],
            },
          ],
        },
        {
          text: "L - 雷丁",
          children: [
            {
              text: "雷丁",
              children: [
                {
                  text: "i3",
                },
                {
                  text: "i5",
                },
                {
                  text: "i9",
                },
              ],
            },
          ],
        },
        {
          text: "M - 马自达",
          children: [
            {
              text: "长安马自达",
              children: [
                {
                  text: "CX-5",
                },
                {
                  text: "CX-8",
                },
                {
                  text: "马自达3 昂克赛拉",
                },
              ],
            },
            {
              text: "一汽马自达",
              children: [
                {
                  text: "CX-4",
                },
                {
                  text: "阿特兹",
                },
              ],
            },
            {
              text: "进口马自达",
              children: [
                {
                  text: "CX-3",
                },
                {
                  text: "MX-5",
                },
                {
                  text: "CX-30",
                },
              ],
            },
            {
              text: "进口马自达新能源",
              children: [
                {
                  text: "MX-30",
                },
              ],
            },
          ],
        },
        {
          text: "M - 名爵",
          children: [
            {
              text: "上汽名爵",
              children: [
                {
                  text: "名爵ZS",
                },
                {
                  text: "名爵GS",
                },
                {
                  text: "名爵HS",
                },
                {
                  text: "名爵3",
                },
                {
                  text: "名爵6",
                },
                {
                  text: "名爵GT",
                },
              ],
            },
            {
              text: "上汽名爵新能源",
              children: [
                {
                  text: "名爵EZS",
                },
                {
                  text: "名爵6 插电混动",
                },
                {
                  text: "名爵eHS",
                },
              ],
            },
          ],
        },
        {
          text: "M - MINI",
          children: [
            {
              text: "进口MINI",
              children: [
                {
                  text: "MINI",
                },
                {
                  text: "CABRIO",
                },
                {
                  text: "CLUBMAN",
                },
                {
                  text: "COUNTRYMAN",
                },
              ],
            },
            {
              text: "进口MINI JCW",
              children: [
                {
                  text: "JCW",
                },
                {
                  text: "JCW COUNTRYMAN",
                },
                {
                  text: "JCW CLUBMAN",
                },
              ],
            },
            {
              text: "进口MINI新能源",
              children: [
                {
                  text: "MINI Electric",
                },
                {
                  text: "COUNTRYMAN 插电混动",
                },
              ],
            },
          ],
        },
        {
          text: "M - 迈凯伦",
          children: [
            {
              text: "迈凯伦",
              children: [
                {
                  text: "540C",
                },
                {
                  text: "570S",
                },
                {
                  text: "570GT",
                },
                {
                  text: "600LT",
                },
                {
                  text: "720S",
                },
                {
                  text: "迈凯伦GT",
                },
              ],
            },
          ],
        },
        {
          text: "M - 玛莎拉蒂",
          children: [
            {
              text: "玛莎拉蒂",
              children: [
                {
                  text: "Levante",
                },
                {
                  text: "Ghibli",
                },
                {
                  text: "Quattroporte",
                },
                {
                  text: "GranTurismo",
                },
                {
                  text: "GranCabrio",
                },
              ],
            },
          ],
        },
        {
          text: "N - 哪吒汽车",
          children: [
            {
              text: "哪吒汽车",
              children: [
                {
                  text: "哪吒N01",
                },
                {
                  text: "哪吒汽车U",
                },
              ],
            },
          ],
        },
        {
          text: "N - 纳智捷",
          children: [
            {
              text: "东风裕隆",
              children: [
                {
                  text: "U5 SUV",
                },
                {
                  text: "优6 SUV",
                },
                {
                  text: "锐3",
                },
                {
                  text: "大7 MPV",
                },
                {
                  text: "URX",
                },
              ],
            },
            {
              text: "东风裕隆新能源",
              children: [
                {
                  text: "U5 SUV EV",
                },
              ],
            },
          ],
        },
        {
          text: "O - 讴歌",
          children: [
            {
              text: "广汽讴歌",
              children: [
                {
                  text: "CDX",
                },
                {
                  text: "RDX",
                },
                {
                  text: "TLX-L",
                },
              ],
            },
            {
              text: "进口讴歌",
              children: [
                {
                  text: "MDX",
                },
                {
                  text: "NSX",
                },
                {
                  text: "Type S",
                },
              ],
            },
          ],
        },
        {
          text: "O - 欧拉",
          children: [
            {
              text: "欧拉",
              children: [
                {
                  text: "欧拉iQ",
                },
                {
                  text: "欧拉R1",
                },
                {
                  text: "欧拉R2",
                },
              ],
            },
          ],
        },
        {
          text: "O - 欧宝",
          children: [
            {
              text: "进口欧宝",
              children: [
                {
                  text: "Corsa",
                },
                {
                  text: "赛飞利",
                },
              ],
            },
            {
              text: "进口欧宝新能源",
              children: [
                {
                  text: "Grandland X PHEV",
                },
                {
                  text: "Corsa-e",
                },
              ],
            },
          ],
        },
        {
          text: "Q - 奇瑞",
          children: [
            {
              text: "奇瑞汽车",
              children: [
                {
                  text: "瑞虎3",
                },
                {
                  text: "瑞虎3x",
                },
                {
                  text: "瑞虎5x",
                },
                {
                  text: "瑞虎7",
                },
                {
                  text: "瑞虎8",
                },
                {
                  text: "艾瑞泽5",
                },
                {
                  text: "艾瑞泽GX",
                },
              ],
            },
            {
              text: "奇瑞新能源",
              children: [
                {
                  text: "瑞虎3xe",
                },
                {
                  text: "瑞虎e",
                },
                {
                  text: "eQ1",
                },
                {
                  text: "艾瑞泽e",
                },
                {
                  text: "艾瑞泽5e",
                },
              ],
            },
          ],
        },
        {
          text: "Q - 启辰",
          children: [
            {
              text: "东风启辰",
              children: [
                {
                  text: "T60",
                },
                {
                  text: "T70",
                },
                {
                  text: "T90",
                },
                {
                  text: "D60",
                },
                {
                  text: "M50V",
                },
              ],
            },
            {
              text: "东风启辰新能源",
              children: [
                {
                  text: "e30",
                },
                {
                  text: "D60 EV",
                },
                {
                  text: "T60 EV",
                },
              ],
            },
          ],
        },
        {
          text: "Q - 起亚",
          children: [
            {
              text: "东风悦达起亚",
              children: [
                {
                  text: "奕跑",
                },
                {
                  text: "智跑",
                },
                {
                  text: "傲跑",
                },
                {
                  text: "KX5",
                },
                {
                  text: "KX7",
                },
                {
                  text: "KX CROSS",
                },
                {
                  text: "K2",
                },
                {
                  text: "K3",
                },
                {
                  text: "K5",
                },
                {
                  text: "焕驰",
                },
                {
                  text: "福瑞迪",
                },
                {
                  text: "凯绅",
                },
              ],
            },
            {
              text: "东风悦达起亚新能源",
              children: [
                {
                  text: "KX3 EV",
                },
                {
                  text: "K3 PHEV",
                },
                {
                  text: "K5 PHEV",
                },
              ],
            },
            {
              text: "进口起亚",
              children: [
                {
                  text: "Seltos",
                },
                {
                  text: "Habaniro",
                },
                {
                  text: "Ceed",
                },
                {
                  text: "XCeed",
                },
              ],
            },
            {
              text: "进口起亚新能源",
              children: [
                {
                  text: "Futuron",
                },
                {
                  text: "e-Soul",
                },
              ],
            },
          ],
        },
        {
          text: "Q - 前途",
          children: [
            {
              text: "前途",
              children: [
                {
                  text: "K20",
                },
                {
                  text: "K25",
                },
                {
                  text: "K50",
                },
              ],
            },
          ],
        },
        {
          text: "Q - 乔治·巴顿",
          children: [
            {
              text: "乔治·巴顿",
              children: [
                {
                  text: "战剑",
                },
                {
                  text: "战车",
                },
              ],
            },
          ],
        },
        {
          text: "Q - 庆铃",
          children: [
            {
              text: "庆铃",
              children: [
                {
                  text: "T17",
                },
                {
                  text: "达咖",
                },
                {
                  text: "轻型商用车",
                },
                {
                  text: "中型商用车",
                },
              ],
            },
          ],
        },
        {
          text: "Q - 全球鹰",
          children: [
            {
              text: "全球鹰",
              children: [
                {
                  text: "全球鹰EX3",
                },
                {
                  text: "全球鹰K27",
                },
              ],
            },
          ],
        },
        {
          text: "Q - 清源汽车",
          children: [
            {
              text: "清源汽车",
              children: [
                {
                  text: "清源小尊",
                },
                {
                  text: "清源尊者",
                },
              ],
            },
          ],
        },
        {
          text: "R - 日产",
          children: [
            {
              text: "东风日产",
              children: [
                {
                  text: "劲客",
                },
                {
                  text: "逍客",
                },
                {
                  text: "奇骏",
                },
                {
                  text: "楼兰",
                },
                {
                  text: "骊威",
                },
                {
                  text: "阳光",
                },
                {
                  text: "骐达",
                },
                {
                  text: "蓝鸟",
                },
                {
                  text: "轩逸",
                },
                {
                  text: "天籁",
                },
                {
                  text: "西玛",
                },
              ],
            },
            {
              text: "东风日产新能源",
              children: [
                {
                  text: "轩逸·纯电",
                },
              ],
            },
            {
              text: "郑州日产",
              children: [
                {
                  text: "途达",
                },
                {
                  text: "NV200",
                },
                {
                  text: "纳瓦拉",
                },
              ],
            },
            {
              text: "进口日产",
              children: [
                {
                  text: "途乐",
                },
                {
                  text: "JUKE",
                },
                {
                  text: "劲客",
                },
                {
                  text: "DAYZ",
                },
                {
                  text: "Note",
                },
                {
                  text: "聆风",
                },
                {
                  text: "Skyline",
                },
                {
                  text: "Serena",
                },
                {
                  text: "纳瓦拉",
                },
                {
                  text: "Titan",
                },
              ],
            },
            {
              text: "进口日产新能源",
              children: [
                {
                  text: "Ariya",
                },
                {
                  text: "IMk",
                },
              ],
            },
          ],
        },
        {
          text: "R - 荣威",
          children: [
            {
              text: "上汽荣威",
              children: [
                {
                  text: "RX3",
                },
                {
                  text: "RX5",
                },
                {
                  text: "RX5 MAX",
                },
                {
                  text: "RX8",
                },
                {
                  text: "i5",
                },
                {
                  text: "i6",
                },
                {
                  text: "360",
                },
                {
                  text: "950",
                },
              ],
            },
            {
              text: "上汽新能源",
              children: [
                {
                  text: "ERX5 纯电动",
                },
                {
                  text: "eRX5 插电混动",
                },
                {
                  text: "Marvel X",
                },
                {
                  text: "Ei5",
                },
                {
                  text: "ei6",
                },
                {
                  text: "e950",
                },
                {
                  text: "RX5 eMAX 插电混动",
                },
              ],
            },
          ],
        },
        {
          text: "R - 瑞驰新能源",
          children: [
            {
              text: "瑞驰新能源",
              children: [
                {
                  text: "EC31",
                },
                {
                  text: "EC35",
                },
                {
                  text: "EK05",
                },
              ],
            },
          ],
        },
        {
          text: "S - 斯柯达",
          children: [
            {
              text: "上汽大众斯柯达",
              children: [
                {
                  text: "柯米克",
                },
                {
                  text: "柯珞克",
                },
                {
                  text: "柯迪亚克",
                },
                {
                  text: "柯迪亚克GT",
                },
                {
                  text: "晶锐",
                },
                {
                  text: "昕锐",
                },
                {
                  text: "昕动",
                },
                {
                  text: "明锐",
                },
                {
                  text: "速派",
                },
              ],
            },
            {
              text: "进口斯柯达",
              children: [
                {
                  text: "Kamiq",
                },
                {
                  text: "SCALA",
                },
                {
                  text: "SCALA G-TEC",
                },
                {
                  text: "VISION GT",
                },
                {
                  text: "速派",
                },
              ],
            },
            {
              text: "进口斯柯达新能源",
              children: [
                {
                  text: "Citigo-e iV",
                },
              ],
            },
          ],
        },
        {
          text: "S - 斯巴鲁",
          children: [
            {
              text: "进口斯巴鲁",
              children: [
                {
                  text: "XV",
                },
                {
                  text: "森林人",
                },
                {
                  text: "傲虎",
                },
                {
                  text: "力狮",
                },
                {
                  text: "BRZ",
                },
                {
                  text: "VIZIV Adrenaline",
                },
                {
                  text: "LEVORG",
                },
                {
                  text: "翼豹",
                },
              ],
            },
            {
              text: "进口斯巴鲁新能源",
              children: [
                {
                  text: "XV 插电混动",
                },
              ],
            },
          ],
        },
        {
          text: "S - smart",
          children: [
            {
              text: "进口smart",
              children: [
                {
                  text: "fortwo",
                },
                {
                  text: "forfour",
                },
              ],
            },
            {
              text: "进口smart新能源",
              children: [
                {
                  text: "fortwo EQ",
                },
                {
                  text: "forfour EQ",
                },
              ],
            },
          ],
        },
        {
          text: "S - SWM斯威",
          children: [
            {
              text: "SWM斯威汽车",
              children: [
                {
                  text: "G01",
                },
                {
                  text: "G05",
                },
                {
                  text: "X3",
                },
                {
                  text: "X7",
                },
                {
                  text: "X2",
                },
              ],
            },
            {
              text: "SWM斯威新能源",
              children: [
                {
                  text: "G01 EV",
                },
              ],
            },
          ],
        },
        {
          text: "S - 思皓",
          children: [
            {
              text: "思皓",
              children: [
                {
                  text: "E20X",
                },
              ],
            },
          ],
        },
        {
          text: "S - 三菱",
          children: [
            {
              text: "广汽三菱",
              children: [
                {
                  text: "劲炫ASX",
                },
                {
                  text: "奕歌",
                },
                {
                  text: "欧蓝德",
                },
              ],
            },
            {
              text: "东南三菱",
              children: [
                {
                  text: "翼神",
                },
              ],
            },
            {
              text: "进口三菱",
              children: [
                {
                  text: "帕杰罗",
                },
                {
                  text: "SUPER HEIGHT K-W",
                },
                {
                  text: "Mirage",
                },
                {
                  text: "L200",
                },
              ],
            },
            {
              text: "进口三菱新能源",
              children: [
                {
                  text: "Mi-Tech",
                },
              ],
            },
          ],
        },
        {
          text: "S - 赛麟",
          children: [
            {
              text: "江苏赛麟",
              children: [
                {
                  text: "迈迈",
                },
                {
                  text: "赛麟S1",
                },
              ],
            },
            {
              text: "进口赛麟",
              children: [
                {
                  text: "赛麟迈克",
                },
                {
                  text: "赛麟S1",
                },
              ],
            },
          ],
        },
        {
          text: "S - 上汽MAXUS",
          children: [
            {
              text: "上汽大通",
              children: [
                {
                  text: "D60",
                },
                {
                  text: "D90",
                },
                {
                  text: "G10",
                },
                {
                  text: "G20",
                },
                {
                  text: "G50",
                },
                {
                  text: "RG10",
                },
                {
                  text: "V80",
                },
                {
                  text: "RV80",
                },
                {
                  text: "V90",
                },
                {
                  text: "T60",
                },
                {
                  text: "T70",
                },
              ],
            },
            {
              text: "上汽大通新能源",
              children: [
                {
                  text: "EG10",
                },
                {
                  text: "EUNIQ 5",
                },
                {
                  text: "EV30",
                },
                {
                  text: "EV80",
                },
                {
                  text: "FCV80",
                },
                {
                  text: "EUNIQ 6 纯电动",
                },
                {
                  text: "EUNIQ 6 插电混动",
                },
              ],
            },
          ],
        },
        {
          text: "S - SERES",
          children: [
            {
              text: "SERES",
              children: [
                {
                  text: "SF5",
                },
              ],
            },
          ],
        },
        {
          text: "S - 双龙",
          children: [
            {
              text: "双龙",
              children: [
                {
                  text: "雷斯特G4",
                },
              ],
            },
          ],
        },
        {
          text: "S - SRM鑫源",
          children: [
            {
              text: "华晨鑫源",
              children: [
                {
                  text: "T20S",
                },
                {
                  text: "T22S",
                },
                {
                  text: "T30S",
                },
                {
                  text: "T32S",
                },
                {
                  text: "X30L",
                },
              ],
            },
            {
              text: "华晨鑫源新能源",
              children: [
                {
                  text: "T20 EV",
                },
                {
                  text: "X30L EV",
                },
                {
                  text: "好运1号",
                },
                {
                  text: "T50 EV",
                },
              ],
            },
          ],
        },
        {
          text: "S - 思铭",
          children: [
            {
              text: "思铭",
              children: [
                {
                  text: "X-NV",
                },
              ],
            },
          ],
        },
        {
          text: "T - 特斯拉",
          children: [
            {
              text: "特斯拉中国",
              children: [
                {
                  text: "Model 3",
                },
              ],
            },
            {
              text: "进口特斯拉",
              children: [
                {
                  text: "Model X",
                },
                {
                  text: "Model 3",
                },
                {
                  text: "Model S",
                },
                {
                  text: "Model Y",
                },
                {
                  text: "Roadster",
                },
              ],
            },
          ],
        },
        {
          text: "T - 腾势",
          children: [
            {
              text: "腾势",
              children: [
                {
                  text: "腾势",
                },
                {
                  text: "腾势X",
                },
              ],
            },
          ],
        },
        {
          text: "T - 天际汽车",
          children: [
            {
              text: "天际汽车",
              children: [
                {
                  text: "天际ME7",
                },
                {
                  text: "天际ME-S",
                },
              ],
            },
          ],
        },
        {
          text: "W - 五菱",
          children: [
            {
              text: "五菱",
              children: [
                {
                  text: "五菱宏光S",
                },
                {
                  text: "五菱宏光S1",
                },
                {
                  text: "五菱宏光S3",
                },
                {
                  text: "五菱宏光PLUS",
                },
                {
                  text: "五菱宏光V",
                },
                {
                  text: "五菱之光",
                },
                {
                  text: "五菱之光V",
                },
                {
                  text: "五菱荣光",
                },
                {
                  text: "五菱荣光V",
                },
                {
                  text: "五菱荣光小卡",
                },
                {
                  text: "五菱荣光新卡",
                },
              ],
            },
          ],
        },
        {
          text: "W - 沃尔沃",
          children: [
            {
              text: "沃尔沃亚太",
              children: [
                {
                  text: "XC40",
                },
                {
                  text: "XC60",
                },
                {
                  text: "S60L",
                },
                {
                  text: "S90",
                },
              ],
            },
            {
              text: "沃尔沃亚太新能源",
              children: [
                {
                  text: "XC60 插电混动",
                },
                {
                  text: "S90 插电混动",
                },
                {
                  text: "S60L 插电混动",
                },
              ],
            },
            {
              text: "进口沃尔沃",
              children: [
                {
                  text: "XC90",
                },
                {
                  text: "V40",
                },
                {
                  text: "V60",
                },
                {
                  text: "V90",
                },
              ],
            },
            {
              text: "进口沃尔沃新能源",
              children: [
                {
                  text: "XC90 插电混动",
                },
                {
                  text: "XC40 纯电动",
                },
              ],
            },
          ],
        },
        {
          text: "W - WEY",
          children: [
            {
              text: "长城汽车",
              children: [
                {
                  text: "VV5",
                },
                {
                  text: "VV6",
                },
                {
                  text: "VV7",
                },
                {
                  text: "VV7 GT",
                },
              ],
            },
            {
              text: "长城新能源",
              children: [
                {
                  text: "P8",
                },
                {
                  text: "WEY-S",
                },
                {
                  text: "WEY-X",
                },
                {
                  text: "P8 GT",
                },
                {
                  text: "VV7 PHEV",
                },
                {
                  text: "VV7 GT PHEV",
                },
              ],
            },
          ],
        },
        {
          text: "W - 蔚来",
          children: [
            {
              text: "蔚来",
              children: [
                {
                  text: "ES6",
                },
                {
                  text: "ES8",
                },
                {
                  text: "EP9",
                },
                {
                  text: "ET7",
                },
              ],
            },
          ],
        },
        {
          text: "W - 五十铃",
          children: [
            {
              text: "江西五十铃",
              children: [
                {
                  text: "牧游侠",
                },
                {
                  text: "瑞迈",
                },
                {
                  text: "铃拓",
                },
                {
                  text: "D-MAX",
                },
              ],
            },
            {
              text: "江西五十铃新能源",
              children: [
                {
                  text: "瑞迈EV",
                },
              ],
            },
          ],
        },
        {
          text: "W - 潍柴汽车",
          children: [
            {
              text: "潍柴汽车",
              children: [
                {
                  text: "英致G3",
                },
                {
                  text: "英致G5",
                },
                {
                  text: "英致727",
                },
                {
                  text: "英致737",
                },
                {
                  text: "潍柴U70",
                },
                {
                  text: "潍柴P80",
                },
              ],
            },
            {
              text: "潍柴汽车新能源",
              children: [
                {
                  text: "EX1",
                },
                {
                  text: "737 EV",
                },
              ],
            },
          ],
        },
        {
          text: "W - 威麟",
          children: [
            {
              text: "威麟",
              children: [
                {
                  text: "H6",
                },
              ],
            },
          ],
        },
        {
          text: "W - 威马汽车",
          children: [
            {
              text: "威马汽车",
              children: [
                {
                  text: "EX5",
                },
                {
                  text: "EX6",
                },
              ],
            },
          ],
        },
        {
          text: "W - 沃克斯豪尔",
          children: [
            {
              text: "进口沃克斯豪尔",
              children: [
                {
                  text: "Astra",
                },
              ],
            },
            {
              text: "进口沃克斯豪尔新能源",
              children: [
                {
                  text: "Corsa e",
                },
              ],
            },
          ],
        },
        {
          text: "X - 雪佛兰",
          children: [
            {
              text: "上汽通用雪佛兰",
              children: [
                {
                  text: "创酷",
                },
                {
                  text: "创界",
                },
                {
                  text: "探界者",
                },
                {
                  text: "科沃兹",
                },
                {
                  text: "科鲁泽",
                },
                {
                  text: "科鲁兹",
                },
                {
                  text: "沃兰多",
                },
                {
                  text: "迈锐宝XL",
                },
                {
                  text: "开拓者",
                },
              ],
            },
            {
              text: "进口雪佛兰",
              children: [
                {
                  text: "科迈罗",
                },
                {
                  text: "库罗德",
                },
                {
                  text: "索罗德",
                },
                {
                  text: "探际者",
                },
                {
                  text: "科尔维特",
                },
              ],
            },
            {
              text: "上汽通用雪佛兰新能源",
              children: [
                {
                  text: "畅巡",
                },
              ],
            },
            {
              text: "进口雪佛兰新能源",
              children: [
                {
                  text: "Bolt",
                },
                {
                  text: "E-10 Concept",
                },
              ],
            },
          ],
        },
        {
          text: "X - 雪铁龙",
          children: [
            {
              text: "东风雪铁龙",
              children: [
                {
                  text: "C3-XR",
                },
                {
                  text: "云逸 C4 AIRCROSS",
                },
                {
                  text: "天逸 C5 AIRCROSS",
                },
                {
                  text: "爱丽舍",
                },
                {
                  text: "C4世嘉",
                },
                {
                  text: "C4L",
                },
                {
                  text: "C5",
                },
                {
                  text: "C6",
                },
              ],
            },
            {
              text: "进口雪铁龙",
              children: [
                {
                  text: "C4 PICASSO",
                },
                {
                  text: "SPACETOURER",
                },
              ],
            },
          ],
        },
        {
          text: "X - 现代",
          children: [
            {
              text: "北京现代",
              children: [
                {
                  text: "ENCINO 昂希诺",
                },
                {
                  text: "ix25",
                },
                {
                  text: "ix35",
                },
                {
                  text: "途胜",
                },
                {
                  text: "胜达",
                },
                {
                  text: "逸行",
                },
                {
                  text: "瑞纳",
                },
                {
                  text: "悦纳",
                },
                {
                  text: "悦纳RV",
                },
                {
                  text: "悦动",
                },
                {
                  text: "领动",
                },
                {
                  text: "LAFESTA 菲斯塔",
                },
                {
                  text: "名图",
                },
                {
                  text: "索纳塔",
                },
              ],
            },
            {
              text: "北京现代新能源",
              children: [
                {
                  text: "ENCINO 昂希诺EV",
                },
                {
                  text: "伊兰特EV",
                },
                {
                  text: "领动PHEV",
                },
                {
                  text: "索纳塔PHEV",
                },
                {
                  text: "LAFESTA 菲斯塔EV",
                },
              ],
            },
            {
              text: "进口现代",
              children: [
                {
                  text: "KONA",
                },
                {
                  text: "i10",
                },
                {
                  text: "i30",
                },
                {
                  text: "伊兰特",
                },
                {
                  text: "iMax",
                },
              ],
            },
            {
              text: "进口现代新能源",
              children: [
                {
                  text: "现代45",
                },
                {
                  text: "Nexo",
                },
              ],
            },
          ],
        },
        {
          text: "X - 小鹏汽车",
          children: [
            {
              text: "小鹏汽车",
              children: [
                {
                  text: "G3",
                },
                {
                  text: "P7",
                },
              ],
            },
          ],
        },
        {
          text: "X - 新宝骏",
          children: [
            {
              text: "新宝骏",
              children: [
                {
                  text: "RS-3",
                },
                {
                  text: "RS-5",
                },
                {
                  text: "RC-6",
                },
                {
                  text: "RM-5",
                },
              ],
            },
          ],
        },
        {
          text: "X - 星途",
          children: [
            {
              text: "星途",
              children: [
                {
                  text: "星途LX",
                },
                {
                  text: "星途TX",
                },
                {
                  text: "星途TXL",
                },
                {
                  text: "星途VX",
                },
              ],
            },
            {
              text: "星途新能源",
              children: [
                {
                  text: "星途LX EV",
                },
              ],
            },
          ],
        },
        {
          text: "X - 新特汽车",
          children: [
            {
              text: "新特汽车",
              children: [
                {
                  text: "DEV 1",
                },
                {
                  text: "MEV 100",
                },
              ],
            },
          ],
        },
        {
          text: "X - 西雅特",
          children: [
            {
              text: "进口西雅特",
              children: [
                {
                  text: "Tarraco",
                },
              ],
            },
            {
              text: "进口西雅特新能源",
              children: [
                {
                  text: "Mii electric",
                },
                {
                  text: "Tarraco PHEV",
                },
              ],
            },
          ],
        },
        {
          text: "Y - 英菲尼迪",
          children: [
            {
              text: "东风英菲尼迪",
              children: [
                {
                  text: "QX50",
                },
                {
                  text: "Q50L",
                },
              ],
            },
            {
              text: "进口英菲尼迪",
              children: [
                {
                  text: "ESQ",
                },
                {
                  text: "QX30",
                },
                {
                  text: "QX60",
                },
                {
                  text: "QX80",
                },
                {
                  text: "Q50",
                },
                {
                  text: "Q60",
                },
                {
                  text: "Q70L",
                },
                {
                  text: "QX55",
                },
                {
                  text: "Q30",
                },
              ],
            },
          ],
        },
        {
          text: "Y - 驭胜",
          children: [
            {
              text: "驭胜",
              children: [
                {
                  text: "S350",
                },
              ],
            },
          ],
        },
        {
          text: "Y - 野马汽车",
          children: [
            {
              text: "野马汽车",
              children: [
                {
                  text: "博骏",
                },
                {
                  text: "T70",
                },
                {
                  text: "T80",
                },
                {
                  text: "斯派卡",
                },
              ],
            },
            {
              text: "野马汽车新能源",
              children: [
                {
                  text: "EC60",
                },
                {
                  text: "EC70",
                },
                {
                  text: "EC30",
                },
              ],
            },
          ],
        },
        {
          text: "Y - 银隆新能源",
          children: [
            {
              text: "银隆新能源",
              children: [
                {
                  text: "艾菲",
                },
                {
                  text: "5024 EV",
                },
                {
                  text: "5043 EV",
                },
              ],
            },
          ],
        },
        {
          text: "Y - 一汽",
          children: [
            {
              text: "天津一汽",
              children: [
                {
                  text: "骏派A50",
                },
                {
                  text: "骏派A70",
                },
                {
                  text: "骏派CX65",
                },
                {
                  text: "骏派D60",
                },
                {
                  text: "骏派D80",
                },
                {
                  text: "威志V5",
                },
                {
                  text: "夏利N5",
                },
                {
                  text: "夏利N7",
                },
              ],
            },
            {
              text: "天津一汽新能源",
              children: [
                {
                  text: "骏派A70E",
                },
                {
                  text: "宾果",
                },
              ],
            },
            {
              text: "一汽吉林",
              children: [
                {
                  text: "森雅R7",
                },
                {
                  text: "森雅R9",
                },
                {
                  text: "T80",
                },
                {
                  text: "T90",
                },
                {
                  text: "V75",
                },
                {
                  text: "V77",
                },
                {
                  text: "V80",
                },
                {
                  text: "森雅R8",
                },
              ],
            },
            {
              text: "一汽红塔",
              children: [
                {
                  text: "蓝舰T340",
                },
              ],
            },
            {
              text: "一汽吉林新能源",
              children: [
                {
                  text: "森雅R7 EV",
                },
                {
                  text: "佳宝V80 EV",
                },
              ],
            },
            {
              text: "一汽解放",
              children: [
                {
                  text: "解放轻型商务车",
                },
              ],
            },
          ],
        },
        {
          text: "Y - 依维柯",
          children: [
            {
              text: "南京依维柯",
              children: [
                {
                  text: "褒迪",
                },
                {
                  text: "得意",
                },
                {
                  text: "欧胜",
                },
                {
                  text: "Ouba",
                },
              ],
            },
            {
              text: "南京依维柯新能源",
              children: [
                {
                  text: "Power Daily 纯电动",
                },
              ],
            },
            {
              text: "进口依维柯",
              children: [
                {
                  text: "Daily",
                },
              ],
            },
          ],
        },
        {
          text: "Y - 云度",
          children: [
            {
              text: "云度",
              children: [
                {
                  text: "云度π1",
                },
                {
                  text: "云度π3",
                },
                {
                  text: "云度π7",
                },
              ],
            },
          ],
        },
        {
          text: "Y - 远程汽车",
          children: [
            {
              text: "远程汽车",
              children: [
                {
                  text: "E5",
                },
                {
                  text: "E6",
                },
                {
                  text: "E200",
                },
                {
                  text: "E200S",
                },
                {
                  text: "RE500",
                },
              ],
            },
          ],
        },
        {
          text: "Y - 裕路",
          children: [
            {
              text: "裕路",
              children: [
                {
                  text: "EV2",
                },
              ],
            },
          ],
        },
        {
          text: "Y - 云雀汽车",
          children: [
            {
              text: "云雀汽车",
              children: [
                {
                  text: "全界Q1",
                },
              ],
            },
          ],
        },
        {
          text: "Y - 宇通",
          children: [
            {
              text: "宇通",
              children: [
                {
                  text: "宇通7系",
                },
              ],
            },
          ],
        },
        {
          text: "Z - 众泰",
          children: [
            {
              text: "众泰汽车",
              children: [
                {
                  text: "SR7",
                },
                {
                  text: "SR9",
                },
                {
                  text: "T300",
                },
                {
                  text: "T500",
                },
                {
                  text: "T600",
                },
                {
                  text: "T600 Coupe",
                },
                {
                  text: "T700",
                },
                {
                  text: "T800",
                },
                {
                  text: "大迈X5",
                },
                {
                  text: "大迈X7",
                },
                {
                  text: "Z360",
                },
                {
                  text: "Z560",
                },
                {
                  text: "Z700",
                },
                {
                  text: "TS5",
                },
              ],
            },
            {
              text: "众泰新能源",
              children: [
                {
                  text: "T300 EV",
                },
                {
                  text: "E200",
                },
                {
                  text: "芝麻",
                },
                {
                  text: "云100",
                },
                {
                  text: "Z500 EV",
                },
                {
                  text: "T100 EV",
                },
                {
                  text: "ET450 EV",
                },
              ],
            },
          ],
        },
        {
          text: "Z - 中华",
          children: [
            {
              text: "中华",
              children: [
                {
                  text: "V3",
                },
                {
                  text: "V6",
                },
                {
                  text: "V7",
                },
                {
                  text: "H3",
                },
                {
                  text: "H530",
                },
                {
                  text: "V9",
                },
              ],
            },
          ],
        },
        {
          text: "Z - Zenvo",
          children: [
            {
              text: "Zenvo",
              children: [
                {
                  text: "Zenvo",
                },
              ],
            },
          ],
        },
        {
          text: "Z - 知豆",
          children: [
            {
              text: "知豆",
              children: [
                {
                  text: "D2",
                },
                {
                  text: "D3",
                },
              ],
            },
          ],
        },
        {
          text: "Z - 之诺",
          children: [
            {
              text: "之诺",
              children: [
                {
                  text: "60H",
                },
              ],
            },
          ],
        },
        {
          text: "Z - 中兴",
          children: [
            {
              text: "中兴",
              children: [
                {
                  text: "小老虎",
                },
                {
                  text: "威虎",
                },
                {
                  text: "大领主",
                },
              ],
            },
          ],
        },
      ],
      car: "",
      houseincome: "",
      job: "",
      education: "",
      city: "",
      birthday: "",
      gender: "",
    };
  },
  created () {
  },
  mounted () {
    console.log('this.$toast', this.$toast)
    this.$toast.loading('加载中', { duration: 0 });
    setTimeout(() => {
      this.$toast.hide()
    }, 2000)
  },
  methods: {
    doWxAuthCb () {
      this.doGetExtProperty()
    },
    // 获取用户信息
    async doGetExtProperty () {
      let params = {
        param: {
        }
      }
      let res = {}
      try {
        res = await getExtProperty(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.hide()
          const result = res.result 
          this.car = result.car || ''
          this.houseincome = result.familyMonthlyIncome || ''
          this.job = result.work || ''
          this.education = result.education || ''
          this.city = result.location || ''
          // this.birthday = result.birthDate || ''
          this.gender = result.gender || ''
          if (result.birthDate) {
            this.birthday = moment(result.birthDate).format('YYYY-MM-DD')
          }
        } else {
          this.$toast.hide()
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    // 设置用户信息
    async doSetExtProperty () {
      let birthday = ''
      if (this.birthday) {
        birthday = moment(this.birthday).valueOf()
      }
      let params = {
        param: {
          gender: this.gender,
          car: this.car,
          familyMonthlyIncome: this.houseincome,
          work: this.job,
          education: this.education,
          location: this.city,
          birthDate: birthday,
        }
      }
      let res = {}
      try {
        res = await setExtProperty(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          console.log('abc')
          this.$toast.hide()
          this.$toast.success('信息已保存');
        } else {
          this.$toast.hide()
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    onCancel() {
      this.pickerVisible = false;
    },
    genderChange() {
      this.pickerType = "gender";
      this.pickerVisible = true;
    },
    birthdayChange() {
      this.pickerType = "birthday";
      this.pickerVisible = true;
    },
    cityChange() {
      this.pickerType = "city";
      this.pickerVisible = true;
    },
    educationChange() {
      this.pickerType = "educatio";
      this.pickerVisible = true;
    },
    jobChange() {
      this.pickerType = "job";
      this.pickerVisible = true;
    },
    houseincomeChange() {
      this.pickerType = "houseincome";
      this.pickerVisible = true;
    },
    carChange() {
      this.pickerType = "car";
      this.pickerVisible = true;
    },
    genderConfirm(val) {
      this.pickerVisible = false;
      this.gender = val;
    },
    birthdayConfirm(val) {
      this.pickerVisible = false;
      this.birthday =
        val.getFullYear() +
        "-" +
        (val.getMonth() + 1 >= 10
          ? val.getMonth() + 1
          : "0" + (val.getMonth() + 1)) +
        "-" +
        (val.getDate() >= 10 ? val.getDate() : "0" + val.getDate());
    },
    cityConfirm(val) {
      this.city = ''
      this.pickerVisible = false;
      val.forEach((item) => {
        this.city += item.name + ",";
      });
      this.city = this.city.slice(0, this.city.length - 1);
    },
    educatioConfirm(val) {
      this.pickerVisible = false;
      this.education = val;
    },
    jobConfirm(val) {
      this.pickerVisible = false;
      this.job = val;
    },
    houseincomeConfirm(val) {
      this.pickerVisible = false;
      this.houseincome = val;
    },
    carConfirm(val) {
      this.pickerVisible = false;
      this.car = val.join(',')
      // this.car = val;
    },
  },
};
</script>
<style lang="css" scoped>
.public-container{
  margin: unset;
}
.weui-btn.public-btn-user-edit-attr{
  padding: unset;
}
</style>